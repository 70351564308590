import { css } from "@emotion/react"
import { DialogContent, Grid, Typography } from "@mui/material"
import { useSelector } from "@xstate/react"
import React from "react"
import { SquaredResponsive } from "src/components/utils/index"
import { useInventory } from "../../atoms"
import ZoomArea from "../ZoomArea"
export interface Props {
  initialIndex: number
}

const ProductImageDefaultImageTab: React.FC<Props> = ({ initialIndex }) => {
  const service = useInventory()
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)

  const [imageIndex, setImageIndex] = React.useState(initialIndex)
  const [toggleState, toggle] = React.useState(false)
  const ref = React.useRef(null)
  React.useEffect(() => {
    import("drift-zoom").then(({ default: Drift }) => {
      ref.current = new Drift(document.querySelector(".dialog-zoom-trigger"), {
        paneContainer: document.querySelector(".dialog-zoom-area"),
        zoomFactor: 5,
      })
      ref.current.disable()
    })
  }, [])

  return (
    <DialogContent
      css={css`
        height: 500px;
        > div {
          height: 100%;
        }

        .left-side {
          text-align: center;

          img.dialog-zoom-trigger {
            max-height: 450px;
            max-width: 100%;
          }

          .dialog-zoom-area {
            &.zoomed {
              cursor: zoom-out;
            }
            cursor: zoom-in;
          }
        }

        .right-side {
          .current {
            img {
              border: 3px solid orange;
            }
          }
        }
      `}
    >
      <Grid container spacing={1}>
        <Grid item xs={9} className="left-side">
          <ZoomArea
            className={["dialog-zoom-area clickable", toggleState && "zoomed"]
              .filter((x) => x)
              .join(" ")}
            onClick={() => {
              if (toggleState) {
                toggle(false)
                ref.current.disable()
                ref.current.trigger._hide()
              } else {
                toggle(true)
                ref.current.enable()
                ref.current.trigger._show()
              }
            }}
            onMouseOut={() => {
              toggle(false)
              ref.current.disable()
            }}
          >
            <img
              className="dialog-zoom-trigger"
              src={vendProduct.images[imageIndex]}
              data-zoom={vendProduct.images[imageIndex]}
            />
          </ZoomArea>
        </Grid>
        <Grid item xs={3} className="right-side">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">{vendProduct.name}</Typography>
            </Grid>
            {vendProduct.images.map((url, index) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                key={index}
                className={index == imageIndex ? "current" : ""}
              >
                <SquaredResponsive>
                  <img
                    src={url}
                    className="clickable"
                    onClick={() => {
                      setImageIndex(index)
                    }}
                  />
                </SquaredResponsive>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  )
}
export default ProductImageDefaultImageTab
