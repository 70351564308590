import { Menu as MenuIcon, Search as SearchIcon } from "@mui/icons-material"
import {
  Box,
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Skeleton,
  SwipeableDrawer,
  useTheme,
} from "@mui/material"
import Link from "next/link"
import React from "react"
import { PRODUCT_CATEGORY_SLUG_PREFIX } from "src/components/Product"
import { useCategoryDrawer, useProductLoader, useSearch } from "src/machines"
interface Props {}
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const CategoryDrawer: React.FC<Props> = (props) => {
  const theme = useTheme()
  const { state, send } = useCategoryDrawer()
  const productLoader = useProductLoader()
  const search = useSearch()
  const { categories } = productLoader.state.context
  const disabled = !search.state.matches("loaded")

  React.useEffect(() => {
    const footer = document.querySelector("footer")
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target == footer) {
            send({ type: "SET.FOOTER.VISIBILITY", visible: entry.isIntersecting })
          }
        })
      },
      {
        threshold: 0.1,
      }
    )

    observer.observe(footer)

    return () => {
      observer?.disconnect()
    }
  }, [])

  return (
    <>
      {state.matches("drawer.closed") && (
        <Box
          sx={{
            zIndex: theme.zIndex.appBar,
            position: "fixed",
            left: 0,
            bottom: "1rem",
          }}
        >
          <Fab
            disabled={disabled}
            sx={{ m: "1rem" }}
            size="small"
            color="primary"
            onClick={() => {
              search.send("SHOW")
            }}
          >
            <SearchIcon />
          </Fab>
          <br />
          <Fab
            disabled={disabled}
            sx={{
              m: "1rem",
              bgcolor: theme.palette.success.light,
              color: theme.palette.grey[100],
            }}
            size="small"
            onClick={() => {
              send("OPEN")
            }}
          >
            <MenuIcon />
          </Fab>
        </Box>
      )}

      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="left"
        open={state.matches("drawer.open")}
        onOpen={() => {}}
        onClose={() => {
          send("CLOSE")
        }}
      >
        <List>
          <ListSubheader disableSticky>Categories</ListSubheader>

          {productLoader.state.matches("loaded")
            ? categories.map(({ id, name, slug }) => (
                <Link
                  key={id}
                  href={`/products/categories/${[PRODUCT_CATEGORY_SLUG_PREFIX, slug].join(
                    "-"
                  )}/${id}/1`}
                  passHref
                >
                  <ListItemButton component="a">
                    <ListItemText primary={name} />
                  </ListItemButton>
                </Link>
              ))
            : [...new Array(8)].map((_, index) => (
                <ListItem key={index}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={100}
                    height={16}
                  />
                </ListItem>
              ))}
        </List>
      </SwipeableDrawer>
    </>
  )
}
export default CategoryDrawer
