import { Delete as DeleteIcon } from "@mui/icons-material"
import { Grid, IconButton, TextField, Typography } from "@mui/material"
import Link from "next/link"
import React from "react"
import { PRODUCT_SLUG_PREFIX } from "src/components/Product"
import { useCart } from "src/machines"
import { decomposePrice, mustBeQuantityValue, toCurrency } from "src/utils"
import CartSummaryAmount from "./CartSummaryAmount"
interface Props {}

const LayoutCartList: React.FC<Props> = (props) => {
  const { state, send } = useCart()

  const items = Array.from(state.context.items)

  return (
    <Grid container spacing={1} className="products cart">
      {items.map(([id, { variant, parentProduct, quantity, maxQuantity }]) => {
        const [whole, fraction] = decomposePrice(variant.priceExcludingTax)
        const error = maxQuantity < quantity
        const helperText = error ? "not enough in stock" : null
        const slug = [PRODUCT_SLUG_PREFIX, parentProduct.slug].join("-")

        return (
          <React.Fragment key={variant.id}>
            <Grid item xs={3}>
              <Link href={`/products/${slug}/${parentProduct.id}`}>
                <a>
                  <img src={parentProduct.imageThumbnailUrl} className="fit" />
                </a>
              </Link>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                  <IconButton
                    onClick={() => {
                      send({
                        type: "DELETE",
                        id: variant.id,
                      })
                    }}
                    data-test-id="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <Link href={`/products/${slug}/${parentProduct.id}`}>
                    <a>
                      <Typography variant="subtitle1">{parentProduct.name}</Typography>
                    </a>
                  </Link>
                </Grid>
                <Grid item xs={3}>
                  <Typography className="price">
                    <span className="currency">$</span>
                    <span className="whole">{whole}</span>
                    <span className="fraction">{fraction}</span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">{variant.variantName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    className="loud-and-clear"
                    variant="outlined"
                    label={toCurrency(variant.priceIncludingTax * quantity)}
                    error={error}
                    helperText={helperText}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: maxQuantity ?? 9999,
                        step: 1,
                      },
                    }}
                    value={quantity}
                    onBlur={() => {
                      if (quantity == 0) {
                        send({
                          type: "DELETE",
                          id: variant.id,
                        })
                      }
                    }}
                    onChange={(event) => {
                      const quantity = mustBeQuantityValue(event.target.value)

                      send({
                        type: "UPSERT",
                        variant,
                        quantity,
                      })
                    }}
                    data-test-id="quantity"
                  />
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )
      })}

      <Grid item xs={4}>
        <Typography variant="h5">Sub Total</Typography>
      </Grid>
      <Grid item xs={8}>
        <CartSummaryAmount amount={state.context.total.sub} />
      </Grid>

      <Grid item xs={4}>
        <Typography variant="h5">Taxes</Typography>
      </Grid>
      <Grid item xs={8}>
        <CartSummaryAmount amount={state.context.total.tax} />
      </Grid>

      <Grid item xs={4}>
        <Typography variant="h5">Total</Typography>
      </Grid>
      <Grid item xs={8}>
        <input
          type="hidden"
          value={state.context.total.grand}
          data-test-id="grand-total"
        />
        <CartSummaryAmount amount={state.context.total.grand} />
      </Grid>
    </Grid>
  )
}
export default LayoutCartList
