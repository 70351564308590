import { atom } from "jotai"
import { Service } from "src/machines/inventory"
import { SheetTitles, SHEET_TITLES } from "src/spreadsheet/sheetTitles"
export * from "./useInventory"
export * from "./useUrlIds"
export { inventoryAtom, idsMapAtom, fetchedAtAtom }

const inventoryAtom = atom<Service, Service>(null, (get, set, update) => {
  set(inventoryAtom, update)
})

export type IDs = string[]
const idsMapAtom = atom<Map<SHEET_TITLES, IDs>, Map<SHEET_TITLES, IDs>>(
  new Map(SheetTitles.map((title) => [title, []])),
  (get, set, update) => {
    set(idsMapAtom, update)
  }
)

const fetchedAtAtom = atom<Map<SHEET_TITLES, Date>, Map<SHEET_TITLES, Date>>(
  new Map(SheetTitles.map((title) => [title, new Date(0)])),
  (get, set, update) => {
    set(fetchedAtAtom, update)
  }
)
