import { assign } from "@xstate/immer"
import { createMachine } from "xstate"

interface Context {
  message: string
}

type Event = { type: "OPEN"; message: string } | { type: "CLOSE" }

export default createMachine<Context, Event>(
  {
    initial: "closed",
    context: {
      message: "",
    },
    states: {
      closing: {
        after: {
          0: "closed",
        },
      },
      closed: {
        on: {
          OPEN: {
            actions: ["setMessage"],
            target: "open",
          },
        },
      },
      open: {
        exit: ["clearMessage"],
        on: {
          CLOSE: {
            target: "closing",
          },
        },
      },
    },
  },
  {
    actions: {
      setMessage: assign((context, event) => {
        if (event.type != "OPEN") return
        context.message = event.message
      }),

      clearMessage: assign((context, event) => {
        context.message = ""
      }),
    },
  }
)
