import { Menu as MenuIcon } from "@mui/icons-material"
import { AppBar, Button, Toolbar } from "@mui/material"
import Link from "next/link"
import React from "react"
import { useCategoryDrawer, useSearch } from "src/machines"
interface Props {}

const SubHeader: React.FC<Props> = (props) => {
  const { state: searchState } = useSearch()
  const { state, send } = useCategoryDrawer()
  const disabled = !searchState.matches("loaded")

  return (
    <AppBar color="default" position="static">
      <Toolbar>
        <Button
          color="inherit"
          disabled={disabled}
          onClick={() => {
            send("OPEN")
          }}
          startIcon={<MenuIcon />}
          sx={{ mr: "1rem" }}
        >
          Categories
        </Button>

        <Link href="/products/selections/featured items" passHref>
          <Button size="small" variant="outlined" sx={{ mr: "1rem" }} color="inherit">
            Featured Items
          </Button>
        </Link>
        <Link href="/products/selections/today's deals" passHref>
          <Button size="small" variant="outlined" sx={{ mr: "1rem" }} color="inherit">
            Today's Deals
          </Button>
        </Link>
        <Link href="/products/selections/best sellers" passHref>
          <Button size="small" variant="outlined" sx={{ mr: "1rem" }} color="inherit">
            Best Sellers
          </Button>
        </Link>
        <Link href="/products/selections/new items" passHref>
          <Button size="small" variant="outlined" sx={{ mr: "1rem" }} color="inherit">
            New Items
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  )
}
export default SubHeader
