import { Container, CssBaseline, ThemeProvider, Typography } from "@mui/material"
import { useRouter } from "next/router"
import React from "react"
import { useLoader } from "src/components/Product"
import { Share } from "src/components/utils/index"
import Footer from "./Footer"
import GlobalCss from "./GlobalCss"
import Header from "./Header"
import Heads from "./Heads"
import theme from "./theme"

interface Props {}

const LayoutDefault: React.FC<Props> = ({ children }) => {
  useLoader()
  const router = useRouter()
  const isProductPath = router.pathname.startsWith("/products")

  return (
    <ThemeProvider theme={theme}>
      <Heads />
      <CssBaseline />
      <GlobalCss />
      <Header />

      <main>
        <Container
          maxWidth="md"
          sx={{
            paddingTop: "1rem",
          }}
        >
          <Typography
            component="div"
            sx={{
              paddingTop: "2rem",
            }}
          >
            {children}
          </Typography>

          {isProductPath && <Share />}
        </Container>
      </main>
      <Footer />
    </ThemeProvider>
  )
}
export default LayoutDefault
