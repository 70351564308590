import { useMediaQuery, useTheme } from "@mui/material"
import React from "react"
import { isMobile } from ".."

const useIsSmallScreen = () => {
  const theme = useTheme()
  const [isXs, set] = React.useState(false)
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"))

  React.useEffect(() => {
    set(!smAndUp)
  }, [smAndUp])

  return isMobile() && isXs
}

export { useIsSmallScreen }
