import { css } from "@emotion/react"
import { DialogContent, Grid, Typography } from "@mui/material"
import { useSelector } from "@xstate/react"
import React from "react"
import { useInventory } from "../../atoms"
interface Props {}

const ProductImageDefaultVideoTab: React.FC<Props> = () => {
  const service = useInventory()
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)
  console.log("vendProduct:", vendProduct)

  return (
    <DialogContent
      css={css`
        height: 500px;
        > div {
          height: 100%;
        }

        iframe {
          width: 100%;
          height: 100%;
        }
      `}
    >
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${vendProduct.youtubeId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">{vendProduct.name}</Typography>
        </Grid>
      </Grid>
    </DialogContent>
  )
}
export default ProductImageDefaultVideoTab
