import {
  PaymentIntent,
  PaymentMethod,
  PaymentMethodCreateParams,
  Stripe,
  StripeCardElement,
  StripeError,
} from "@stripe/stripe-js"
import { assign } from "@xstate/immer"
import {
  confirmPaymentResponse,
  GraphqlError,
} from "pages/api/user/order/[vendSaleId]/payment/confirm"
import { confirmStripePaymentResponse } from "pages/api/user/order/[vendSaleId]/payment/stripe/[paymentIntentId]/confirm"
import { Generated } from "src/components/Apollo"
import { createMachine } from "xstate"

export type Order = Generated.OrdersQuery["orders"][0]
type BillingDetails = PaymentMethodCreateParams.BillingDetails

interface Context {
  order: Order
  billingDetails: BillingDetails

  cardValidity: boolean

  payment: {
    open: boolean
    amount: number
    min: number
  }

  stripe: {
    core: Stripe
    cardElement: StripeCardElement
    paymentMethod: PaymentMethod
    paymentIntent: PaymentIntent
    clientSecret: string
  }

  progress: number

  errors: {
    graphql: GraphqlError
    stripe: StripeError
  }
}

type Event =
  | {
      type: "PAY.ADDITIONAL"
      stripe: Stripe
      cardElement: StripeCardElement
      amount: number
    }
  | { type: "OPEN" }
  | { type: "CLOSE" }
  | { type: "CONFIRM" }
  | { type: "UNCONFIRM" }
  | { type: "CARD.UPDATED"; valid: boolean }
  | { type: "ERROR.STRIPE_PAYMENT_METHOD"; error: StripeError }
  | { type: "ERROR.STRIPE_PAYMENT_CONFIRM"; error: StripeError }
  | { type: "ERROR.API_CHECKOUT_CONFIRM"; error: GraphqlError }
  | { type: "ERROR.API_STRIPE_CONFIRM"; error: GraphqlError }
  | { type: "done.invoke.creatingPaymentMethodWithStripe"; data: PaymentMethod }
  | {
      type: "done.invoke.creatingPaymentIntentWithBackend"
      data: confirmPaymentResponse
    }
  | { type: "done.invoke.confirmingCardPaymentWithStripe"; data: PaymentIntent }
  | {
      type: "done.invoke.confirmingCardPaymentWithBackend"
      data: confirmStripePaymentResponse
    }

export default createMachine<Context, Event>(
  {
    id: "order",
    initial: "idle",
    context: {
      order: null,
      billingDetails: null,
      cardValidity: false,

      payment: {
        open: false,
        amount: 0,
        min: 0,
      },

      stripe: {
        core: null,
        cardElement: null,
        paymentMethod: null,
        paymentIntent: null,
        clientSecret: null,
      },

      progress: 0,

      errors: {
        graphql: null,
        stripe: null,
      },
    },

    on: {
      OPEN: {
        actions: ["setOpen"],
      },
      CLOSE: {
        actions: ["setClose"],
        target: "idle",
      },
    },
    states: {
      idle: {
        always: [
          {
            cond: "isVoided",
            target: "voided",
          },
          {
            cond: "paidInFull",
            target: "paidInFull",
          },
          {
            target: "notPaidInFull",
          },
        ],
      },
      voided: {
        type: "final",
      },
      paidInFull: {
        type: "final",
      },
      notPaidInFull: {
        entry: ["setMinPayment"],
        on: {
          "CARD.UPDATED": {
            actions: ["setCardValidity"],
            target: "cardIsValid",
          },
        },
      },
      cardIsValid: {
        always: [
          {
            cond: "isCardInvalid",
            target: "notPaidInFull",
          },
        ],
        on: {
          CONFIRM: {
            target: "confirmedToPay",
          },
          "CARD.UPDATED": {
            actions: ["setCardValidity"],
            target: "cardIsValid",
          },
        },
      },
      confirmedToPay: {
        entry: ["initProgress"],
        on: {
          UNCONFIRM: {
            target: "cardIsValid",
          },
          "PAY.ADDITIONAL": {
            actions: ["setStripe"],
            target: "processingPayment",
          },
          "CARD.UPDATED": {
            actions: ["setCardValidity"],
            target: "cardIsValid",
          },
        },
      },
      processingPayment: {
        initial: "creatingPaymentMethodWithStripe",
        states: {
          creatingPaymentMethodWithStripe: {
            invoke: {
              id: "creatingPaymentMethodWithStripe",
              src: "createPaymentMethodWithStripe",
              onDone: {
                actions: ["setPaymentMethod", "increaseProgress"],
                target: "creatingPaymentIntentWithBackend",
              },
              onError: {
                target: "#order.idle",
              },
            },
          },
          creatingPaymentIntentWithBackend: {
            invoke: {
              id: "creatingPaymentIntentWithBackend",
              src: "confirmPaymentWithBackend",
              onDone: {
                actions: ["setClientSecret", "increaseProgress"],
                target: "confirmingCardPaymentWithStripe",
              },
              onError: {
                target: "#order.idle",
              },
            },
          },
          confirmingCardPaymentWithStripe: {
            invoke: {
              id: "confirmingCardPaymentWithStripe",
              src: "confirmCardPaymentWithStripe",
              onDone: {
                actions: ["setPaymentIntent", "increaseProgress"],
                target: "confirmingCardPaymentWithBackend",
              },
              onError: {
                target: "#order.idle",
              },
            },
          },
          confirmingCardPaymentWithBackend: {
            invoke: {
              id: "confirmingCardPaymentWithBackend",
              src: "confirmCardPaymentWithBackend",
              onDone: {
                actions: ["resetOrder", "doneProgress"],
                target: "#order.done",
              },
              onError: {
                target: "#order.idle",
              },
            },
          },
        },
        on: {
          "ERROR.STRIPE_PAYMENT_METHOD": {
            actions: ["setStripeError"],
          },
          "ERROR.STRIPE_PAYMENT_CONFIRM": {
            actions: ["setStripeError"],
          },
          "ERROR.API_CHECKOUT_CONFIRM": {
            actions: ["setGraphqlError"],
          },
          "ERROR.API_STRIPE_CONFIRM": {
            actions: ["setGraphqlError"],
          },
        },
      },
      done: {
        after: {
          0: {
            target: "idle",
          },
        },
      },
    },
  },
  {
    services: {
      createPaymentMethodWithStripe: (context, event) => (callback, onReceive) =>
        new Promise<PaymentMethod>(async (resolve, reject) => {
          const {
            stripe: { core, cardElement },
            billingDetails,
          } = context

          const paymentMethodResult = await core.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
          })
          if (paymentMethodResult.error) {
            callback({
              type: "ERROR.STRIPE_PAYMENT_METHOD",
              error: paymentMethodResult.error,
            })
            reject()
            return
          }

          const { paymentMethod } = paymentMethodResult
          resolve(paymentMethod)
        }),
      confirmPaymentWithBackend: (context, event) => (callback, onReceive) =>
        new Promise<confirmPaymentResponse>(async (resolve, reject) => {
          const {
            order,
            payment: { amount },
            stripe: { paymentMethod },
          } = context

          const confirmPaymentResponse = await fetch(
            `/api/user/order/${order.id}/payment/confirm`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                paymentMethodId: paymentMethod.id,
                amount,
              }),
            }
          )

          const confirmCheckoutJson: confirmPaymentResponse =
            await confirmPaymentResponse.json()
          console.log("confirmCheckoutJson:", confirmCheckoutJson)
          if (confirmCheckoutJson.error) {
            callback({
              type: "ERROR.API_CHECKOUT_CONFIRM",
              error: confirmCheckoutJson.error,
            })
            reject()
            return
          }

          resolve(confirmCheckoutJson)
        }),
      confirmCardPaymentWithStripe: (context, event) => (callback, onReceive) =>
        new Promise<PaymentIntent>(async (resolve, reject) => {
          const { clientSecret, paymentMethod } = context.stripe

          const cardPaymentResult = await context.stripe.core.confirmCardPayment(
            clientSecret,
            {
              payment_method: paymentMethod.id,
            }
          )
          if (cardPaymentResult.error) {
            callback({
              type: "ERROR.STRIPE_PAYMENT_CONFIRM",
              error: cardPaymentResult.error,
            })

            reject()
            return
          }

          resolve(cardPaymentResult.paymentIntent)
        }),

      confirmCardPaymentWithBackend: (context, event) => (callback, onReceive) =>
        new Promise<confirmStripePaymentResponse>(async (resolve, reject) => {
          const {
            order,
            stripe: { paymentIntent },
          } = context

          const orderPaymentStripeConfirmResponse = await fetch(
            `/api/user/order/${order.id}/payment/stripe/${paymentIntent.id}/confirm`
          )

          const orderPaymentStripeConfirmJson: confirmStripePaymentResponse =
            await orderPaymentStripeConfirmResponse.json()

          if (orderPaymentStripeConfirmJson.error) {
            callback({
              type: "ERROR.API_CHECKOUT_CONFIRM",
              error: orderPaymentStripeConfirmJson.error,
            })
            reject()
            return
          }

          resolve(orderPaymentStripeConfirmJson)
        }),
    },
    actions: {
      setMinPayment: assign((context, event) => {
        context.payment.min = Math.min(
          process.env.NEXT_CONFIG_LAYAWAY_MIN_PAYMENT,
          context.order.balance
        )
      }),
      setCardValidity: assign((context, event) => {
        if (event.type != "CARD.UPDATED") return

        context.cardValidity = event.valid
      }),

      setStripe: assign((context, event) => {
        if (event.type != "PAY.ADDITIONAL") return

        context.payment.amount = event.amount

        context.stripe.core = event.stripe
        context.stripe.cardElement = event.cardElement
        context.stripe.paymentMethod = null
        context.stripe.clientSecret = null
      }),

      setPaymentMethod: assign((context, event) => {
        if (event.type != "done.invoke.creatingPaymentMethodWithStripe") return
        context.stripe.paymentMethod = event.data
      }),

      setClientSecret: assign((context, event) => {
        if (event.type != "done.invoke.creatingPaymentIntentWithBackend") return
        const {
          data: {
            preparePayment: { clientSecret },
          },
        } = event.data
        context.stripe.clientSecret = clientSecret
      }),

      setPaymentIntent: assign((context, event) => {
        if (event.type != "done.invoke.confirmingCardPaymentWithStripe") return
        context.stripe.paymentIntent = event.data
      }),

      resetOrder: assign((context, event) => {
        if (event.type != "done.invoke.confirmingCardPaymentWithBackend") return
        const {
          data: {
            makePayment: { order },
          },
        } = event.data
        context.order = order
      }),

      setOpen: assign((context, event) => {
        context.payment.open = true
      }),
      setClose: assign((context, event) => {
        context.payment.open = false
      }),

      setGraphqlError: assign((context, event) => {
        if (
          event.type == "ERROR.API_CHECKOUT_CONFIRM" ||
          event.type == "ERROR.API_STRIPE_CONFIRM"
        ) {
          context.errors.graphql = event.error
        }
      }),

      setStripeError: assign((context, event) => {
        if (
          event.type == "ERROR.STRIPE_PAYMENT_METHOD" ||
          event.type == "ERROR.STRIPE_PAYMENT_CONFIRM"
        ) {
          context.errors.stripe = event.error
        }
      }),
      initProgress: assign((context, event) => {
        context.progress = 0
      }),
      increaseProgress: assign((context, event) => {
        context.progress += 30
      }),
      doneProgress: assign((context, event) => {
        context.progress = 100
      }),
    },
    guards: {
      isVoided: (context, envet) => context.order.status == "VOIDED",
      paidInFull: (context, event) => context.order.paidInFull,
      isCardInvalid: (context, envet) => !context.cardValidity,
    },
  }
)
