import React from "react"
import { SOLID_COLORS } from "../../ColorCheckbox"
import ButtonBase, { Props } from "../ButtonBase"
import ThreeTone from "../tones/ThreeTone"

const LayoutSearchMultiColorRWB: React.FC<Props> = (props) => {
  return (
    <ButtonBase {...props}>
      <ThreeTone colors={[SOLID_COLORS.red, SOLID_COLORS.white, SOLID_COLORS.blue]} />
    </ButtonBase>
  )
}
export default LayoutSearchMultiColorRWB
