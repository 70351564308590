import { Grid, Link as MuiLink, Typography } from "@mui/material"
import Link from "next/link"
import React from "react"
import ListItem from "../ListItem"
interface Props {
  vendProducts: VendProduct[]
}

const ProductPromotionNewItems: React.FC<Props> = ({ vendProducts }) => {
  const smSize = 12 / process.env.NEXT_CONFIG_LIST.ITEMS_PER_ROW

  if (vendProducts.length == 0) {
    return null
  }

  return (
    <Grid container spacing={2} className="products list">
      <Grid item xs={12}>
        <Link href="/products/selections/new items" passHref>
          <MuiLink underline="hover">
            <Typography variant="h2">New Items</Typography>
          </MuiLink>
        </Link>
      </Grid>
      {vendProducts.slice(0, 4).map((vendProduct) => (
        <Grid item xs={12} sm={smSize as any} key={vendProduct.id}>
          <ListItem vendProduct={vendProduct} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ProductPromotionNewItems
