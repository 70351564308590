import React from "react"
import { SOLID_COLORS } from "../../ColorCheckbox"
import ButtonBase, { Props } from "../ButtonBase"
import TwoTone from "../tones/TwoTone"

const LayoutSearchMultiColorGB: React.FC<Props> = (props) => {
  return (
    <ButtonBase {...props}>
      <TwoTone colors={[SOLID_COLORS.black, SOLID_COLORS.yellow]} />
    </ButtonBase>
  )
}
export default LayoutSearchMultiColorGB
