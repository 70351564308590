import { Typography } from "@mui/material"
import React from "react"
import { decomposePrice } from "src/utils/index"
interface Props {
  amount: number
}

const CartSummaryAmount: React.FC<Props> = ({ amount }) => {
  const [whole, fraction] = decomposePrice(amount)

  return (
    <Typography align="right" variant="h5" className="price" gutterBottom>
      <span className="currency">$</span>
      <span className="whole">{whole}</span>
      <span className="fraction">{fraction}</span>
    </Typography>
  )
}
export default CartSummaryAmount
