import React from "react"
import { SOLID_COLORS } from "../../ColorCheckbox"
import ButtonBase, { Props } from "../ButtonBase"
import SevenTone from "../tones/SevenTone"

const LayoutSearchMultiColorRAINBOW: React.FC<Props> = (props) => {
  return (
    <ButtonBase {...props}>
      <SevenTone
        colors={[
          SOLID_COLORS.red,
          SOLID_COLORS.orange,
          SOLID_COLORS.yellow,
          SOLID_COLORS.green,
          SOLID_COLORS.blue,
          SOLID_COLORS.indigo,
          SOLID_COLORS.purple,
        ]}
      />
    </ButtonBase>
  )
}
export default LayoutSearchMultiColorRAINBOW
