import { Check as CheckIcon } from "@mui/icons-material"
import { Box, Button, Tooltip } from "@mui/material"
import {
  blue,
  brown,
  green,
  grey,
  indigo,
  orange,
  pink,
  purple,
  red,
  yellow,
} from "@mui/material/colors"
import React from "react"
import { useSearch } from "src/machines"
import { Builder } from "./MultiColors"
import RAINBOW from "./MultiColors/pre-defined/RAINBOW"
export const SOLID_COLORS = {
  red: red.A700,
  pink: pink[200],
  purple: purple.A700,
  blue: blue.A700,
  indigo: indigo.A700,
  green: green.A700,
  yellow: yellow.A700,
  // gold: yellow.A700,
  orange: orange.A700,
  brown: brown[500],
  grey: grey.A700,
  gray: grey.A700,
  black: "#000",
  white: "#FFF",
}

interface Props {
  color: string
}

const ColorCheckbox: React.FC<Props> = ({ color }) => {
  const { state, send } = useSearch()

  const currentlySelected = state.context.query.attributes.colorIds
  const selected = currentlySelected.includes(color)

  const toggle = React.useCallback(() => {
    send({
      type: currentlySelected.includes(color) ? "DESELECT" : "SELECT",
      kind: "colorIds",
      id: color,
    })
  }, [state.context.query.attributes])

  if (color.includes("/")) {
    return <Builder color={color} colors={color.split("/")} />
  }

  if (color === "rainbow") {
    return <RAINBOW color={color} />
  }

  let bgcolor = ""
  if (Object.keys(SOLID_COLORS).includes(color)) {
    bgcolor = SOLID_COLORS[color]
  }

  return (
    <Button
      onClick={() => {
        toggle()
      }}
    >
      <Tooltip title={color} placement="top">
        <Box bgcolor={bgcolor} className={["color", color].join(" ")}>
          {selected && <CheckIcon className="check" />}
        </Box>
      </Tooltip>
    </Button>
  )
}
export default ColorCheckbox
