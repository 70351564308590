import { css } from "@emotion/react"
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Search as SearchIcon,
} from "@mui/icons-material"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SwipeableDrawer,
  TextField,
  Typography,
} from "@mui/material"
import { purple } from "@mui/material/colors"
import Router, { useRouter } from "next/router"
import React from "react"
import { useBase64Params } from "src/components/utils"
import { useProductLoader, useSearch } from "src/machines"
import { isMobile } from "src/utils"
import { useIsSmallScreen } from "src/utils/hooks"
import CheckboxLikeButton from "./CheckboxLikeButton"
import ColorCheckbox from "./ColorCheckbox"

interface Props {}
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const colorSort = (colorTags: VendProductTag[]) =>
  [...colorTags].sort(({ name: a }, { name: b }) => {
    const aIsMulti = a.includes("/") || a == "rainbow"
    const bIsMulti = b.includes("/") || a == "rainbow"
    if (aIsMulti && !bIsMulti) {
      return 1
    }
    if (!aIsMulti && bIsMulti) {
      return -1
    }
    if (!aIsMulti && !bIsMulti) {
      return a.toLowerCase() < b.toLowerCase() ? 1 : -1
    }

    return 0
  })

const LayoutSearchDrawer: React.FC<Props> = (props) => {
  const router = useRouter()
  const { state: productLoaderState } = useProductLoader()
  const { state, send } = useSearch()
  const { encode, decode } = useBase64Params()
  const isSmallScreen = useIsSmallScreen()

  const { vendProducts } = productLoaderState.context
  const isIdle = state.matches("idle")

  React.useEffect(() => {
    Router.events.on("beforeHistoryChange", (url: string) => {
      if (!url.startsWith("/products/search")) {
        send({ type: "CLEAR.FILTER" })
      }
    })
  }, [])

  React.useEffect(() => {
    // otherwise params is empty then filled
    if (!router.isReady) return

    const params = decode(router.query.params as string)
    if (Object.keys(params).length == 0) return

    send({
      type: "SETUP",
      vendProducts,
      query: params,
    })
  }, [vendProducts, router.isReady])

  const searchButton = (
    <CardActions>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        startIcon={isIdle && <CircularProgress size={24} />}
        disabled={!state.matches("loaded.readyToQuery")}
      >
        <SearchIcon />
        Search
      </Button>
    </CardActions>
  )

  const {
    tags: { categories: tagCategories, colors: tagColors, others: tagOthers },
  } = state.context

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor="right"
      open={state.context.open}
      onOpen={() => {}}
      onClose={() => {
        send("HIDE")
      }}
    >
      <Box
        sx={{
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <Card
          elevation={0}
          css={css`
            width: 400px;
            max-width: 100vw;
            padding-bottom: 40vh;
            .avatar {
              background-color: ${purple.A700};
            }

            .gutterBottom {
              margin-bottom: 3rem;
            }

            .colors {
              background-color: #eaeaea;
              .check {
                position: absolute;
                top: 25%;
                left: 25%;
                color: white;
              }

              .color {
                position: relative;
                padding-top: 100%;
                width: 100%;
                &.white {
                  .check {
                    color: black;
                  }
                }
                &.rainbow {
                  .check {
                    color: black;
                  }
                }
                &.gold {
                  .check {
                    color: black;
                  }
                  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f8b500+3,f9f295+22,e0aa3e+50,f9f295+78,f8b500+100 */
                  background: rgb(248, 181, 0); /* Old browsers */
                  background: -moz-linear-gradient(
                    -45deg,
                    rgba(248, 181, 0, 1) 3%,
                    rgba(249, 242, 149, 1) 22%,
                    rgba(224, 170, 62, 1) 50%,
                    rgba(249, 242, 149, 1) 78%,
                    rgba(248, 181, 0, 1) 100%
                  ); /* FF3.6-15 */
                  background: -webkit-linear-gradient(
                    -45deg,
                    rgba(248, 181, 0, 1) 3%,
                    rgba(249, 242, 149, 1) 22%,
                    rgba(224, 170, 62, 1) 50%,
                    rgba(249, 242, 149, 1) 78%,
                    rgba(248, 181, 0, 1) 100%
                  ); /* Chrome10-25,Safari5.1-6 */
                  background: linear-gradient(
                    135deg,
                    rgba(248, 181, 0, 1) 3%,
                    rgba(249, 242, 149, 1) 22%,
                    rgba(224, 170, 62, 1) 50%,
                    rgba(249, 242, 149, 1) 78%,
                    rgba(248, 181, 0, 1) 100%
                  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8b500', endColorstr='#f8b500',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                }
                &.silver {
                  .check {
                    color: black;
                  }
                  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f6f8f9+0,e5ebee+50,d7dee3+51,f5f7f9+100;White+Gloss */
                  background: rgb(246, 248, 249); /* Old browsers */
                  background: -moz-linear-gradient(
                    -45deg,
                    rgba(246, 248, 249, 1) 0%,
                    rgba(229, 235, 238, 1) 50%,
                    rgba(215, 222, 227, 1) 51%,
                    rgba(245, 247, 249, 1) 100%
                  ); /* FF3.6-15 */
                  background: -webkit-linear-gradient(
                    -45deg,
                    rgba(246, 248, 249, 1) 0%,
                    rgba(229, 235, 238, 1) 50%,
                    rgba(215, 222, 227, 1) 51%,
                    rgba(245, 247, 249, 1) 100%
                  ); /* Chrome10-25,Safari5.1-6 */
                  background: linear-gradient(
                    135deg,
                    rgba(246, 248, 249, 1) 0%,
                    rgba(229, 235, 238, 1) 50%,
                    rgba(215, 222, 227, 1) 51%,
                    rgba(245, 247, 249, 1) 100%
                  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f8f9', endColorstr='#f5f7f9',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                }
              }

              .multi-color {
                position: relative;
                padding-top: 100%;
                width: 100%;
                > div {
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                &.green_white {
                  .check {
                    color: black;
                  }
                }
                &.red_white_blue {
                  .check {
                    color: black;
                  }
                }
              }
            }
          `}
        >
          <form
            onSubmit={(event) => {
              event.preventDefault()

              if (state.matches("loaded.readyToQuery")) {
                Router.push(`/products/search/${encode(state.context.query)}/1`)
                send({ type: "QUERY" })

                if (isSmallScreen) {
                  send("HIDE")
                }
              }
            }}
          >
            <CardHeader
              avatar={
                <Avatar className="avatar">
                  <SearchIcon />
                </Avatar>
              }
              title="Search"
              action={
                <IconButton
                  onClick={() => {
                    send("HIDE")
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              }
            />
            {searchButton}
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      value={state.context.query.categoryId}
                      onChange={(event) => {
                        send({
                          type: "SET.INPUTS",
                          categoryId: event.target.value as string,
                        })
                      }}
                    >
                      <MenuItem value="">All</MenuItem>
                      {productLoaderState.context.categories.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoFocus={!isMobile()}
                    name="keywords"
                    fullWidth
                    variant="outlined"
                    placeholder="keywords ..."
                    className="loud-and-clear"
                    value={state.context.query.keywords}
                    onChange={(event) => {
                      send({
                        type: "SET.INPUTS",
                        keywords: event.target.value,
                      })
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: "2rem" }}>
                  <Typography variant="caption" component="p">
                    Sub Category
                  </Typography>
                  <Grid container spacing={1} className="gutterBottom">
                    {tagCategories.map(({ id, name }, index) => (
                      <Grid item key={id}>
                        <CheckboxLikeButton id={id} kind="categoryIds">
                          {name}
                        </CheckboxLikeButton>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                <Grid item xs={12} className="colors gutterBottom">
                  <Typography variant="caption" component="p">
                    Color
                  </Typography>

                  {colorSort(tagColors).map(({ name }, index) => (
                    <ColorCheckbox key={name} color={name} />
                  ))}
                </Grid>
                <Grid item xs={12}>
                  {Object.entries(tagOthers).map(([categoryName, tagsInThisCategory]) => (
                    <React.Fragment key={categoryName}>
                      <Typography variant="caption" component="p">
                        {categoryName}
                      </Typography>
                      <Grid container spacing={1} className="gutterBottom">
                        {tagsInThisCategory.map(({ id, name }) => (
                          <Grid item key={id}>
                            <CheckboxLikeButton id={id} kind="otherIds">
                              {name}
                            </CheckboxLikeButton>
                          </Grid>
                        ))}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" component="p" gutterBottom>
                    Price Range
                  </Typography>

                  <Grid container spacing={2} justifyContent="space-around">
                    <Grid item xs={4}>
                      <TextField
                        name="priceFrom"
                        type="number"
                        fullWidth
                        label="min"
                        placeholder="$"
                        variant="outlined"
                        className="loud-and-clear"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        value={state.context.query.priceFrom || ""}
                        onChange={(event) => {
                          send({
                            type: "SET.INPUTS",
                            priceFrom: parseFloat(event.target.value),
                          })
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        name="priceTo"
                        type="number"
                        fullWidth
                        label="max"
                        placeholder="$"
                        variant="outlined"
                        className="loud-and-clear"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        value={state.context.query.priceTo || ""}
                        onChange={(event) => {
                          send({
                            type: "SET.INPUTS",
                            priceTo: parseFloat(event.target.value),
                          })
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            {searchButton}
            <CardActions>
              <Button
                fullWidth
                size="large"
                onClick={() => {
                  send("HIDE")
                }}
              >
                <ArrowBackIcon /> Back to shopping
              </Button>
            </CardActions>
            <CardActions>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  send({ type: "CLEAR.FILTER" })
                }}
              >
                Clear All
              </Button>
            </CardActions>
          </form>
        </Card>
      </Box>
    </SwipeableDrawer>
  )
}
export default LayoutSearchDrawer
