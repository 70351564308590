import { Grid, NoSsr, Typography } from "@mui/material"
import { useInterpret, useSelector } from "@xstate/react"
import { Atom } from "jotai"
import { useHydrateAtoms } from "jotai/utils"
import React from "react"
import ReactMarkdown from "react-markdown"
import breaks from "remark-breaks"
import { Share } from "src/components/utils/index"
import { createInventory, Service } from "src/machines/inventory"
import AddToCart from "./AddToCart"
import { inventoryAtom } from "./atoms"
import Breadcrumbs from "./Breadcrumbs"
import CompositeList from "./CompositeList"
import Images from "./Images"
import ZoomArea from "./Images/ZoomArea"
import { RelatedItems } from "./Promotion"

interface Props {
  machine: ReturnType<typeof createInventory>
  onLoad: () => void
}

const ProductForm: React.FC<Props> = ({ machine, onLoad }) => {
  const service = useInterpret(machine)
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)
  const element: [[Atom<Service>, Service]] = [[inventoryAtom, service]]
  useHydrateAtoms(element)

  React.useEffect(() => {
    onLoad()
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs vendProduct={vendProduct} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Images />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ZoomArea className="zoom-area">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <Typography variant="h4" gutterBottom>
                {vendProduct.name}
              </Typography>
              <Typography variant="caption" component="p">
                Description
              </Typography>
              <div>
                <ReactMarkdown
                  plugins={[breaks]}
                  escapeHtml={false}
                  linkTarget="_blank"
                  source={vendProduct.description}
                />
              </div>

              <Share />
            </Grid>
            <Grid item xs={12} sm={5}>
              <NoSsr>
                <AddToCart />
              </NoSsr>
            </Grid>

            <Grid item xs={12}>
              <CompositeList vendProduct={vendProduct} />
            </Grid>
          </Grid>
        </ZoomArea>
      </Grid>

      <Grid item xs={12}>
        <RelatedItems />
      </Grid>
    </Grid>
  )
}
export default ProductForm
