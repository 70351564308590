import { useAtom } from "jotai"
import { atomWithMachine } from "jotai/xstate"
import { globalScope } from "."
import productLoaderMachine from "./productLoader"

const productLoaderAtom = atomWithMachine((get) => productLoaderMachine)

const useProductLoader = () => {
  const [state, send] = useAtom(productLoaderAtom, globalScope)

  return {
    state,
    send,
  }
}

export { useProductLoader }
