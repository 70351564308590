const toCurrency = (number: number) => {
  const formatted = new Intl.NumberFormat("en", {
    style: "currency",
    currency: "usd",
  })
    .format(number)
    .replace(/\u00a0/g, " ")

  const [integer, decimal] = formatted.split(".")
  return parseFloat(decimal) == 0 ? integer : formatted
}

const isMobile = () => {
  if (typeof navigator === "undefined") {
    return false
  }

  const touchPoints = "maxTouchPoints" in navigator ? navigator.maxTouchPoints : 0
  return touchPoints > 0
}

const fraction2 = Intl.NumberFormat("en", { maximumFractionDigits: 2 })

const decomposePrice = (price: number): [string, string] => {
  const [whole, fraction] = toCurrency(price).replace("$", "").split(".")
  return [whole, fraction]
}

const mustBeQuantityValue = (value: number | string) => {
  const intValue = parseInt(Number(value).toString(), 10)

  if (
    intValue < 0 ||
    isNaN(intValue) ||
    typeof intValue == "undefined" ||
    !Number.isInteger(intValue)
  ) {
    return 0
  }

  return Math.min(9999, intValue)
}

export { toCurrency, isMobile, fraction2, decomposePrice, mustBeQuantityValue }
