import { useAtom } from "jotai"
import { atomWithMachine } from "jotai/xstate"
import { globalScope } from "."
import errorMessageMachine from "./errorMessage"

const errorMessageAtom = atomWithMachine((get) => errorMessageMachine)

const useErrorMessage = () => {
  const [state, send] = useAtom(errorMessageAtom, globalScope)

  return {
    state,
    send,
  }
}

export { useErrorMessage }
