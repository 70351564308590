import {
  PaymentIntent,
  PaymentMethod,
  PaymentMethodCreateParams,
  Stripe,
  StripeCardElement,
  StripeError,
} from "@stripe/stripe-js"
import { assign } from "@xstate/immer"
import { ConfirmCheckoutResponse, GraphqlError } from "pages/api/user/checkout/confirm"
import { ConfirmStripePaymentResponse } from "pages/api/user/checkout/stripe/[paymentIntentId]/confirm"
import { Generated } from "src/components/Apollo"
import { actions, createMachine } from "xstate"
import { Item, Items, Total } from "./cart"
const { log } = actions

type BillingDetails = PaymentMethodCreateParams.BillingDetails

interface Context {
  // take a snapshot of the current cart
  // this way even if the customer updates the cart somewhere else
  // it won't affect this checkout process
  cartItemSnapshot: Item[]
  total: Total
  layaway: {
    min: number
    amount: number
  }
  billingDetails: BillingDetails
  cardValidity: boolean

  stripe: {
    core: Stripe
    cardElement: StripeCardElement
    paymentMethod: PaymentMethod
    clientSecret: string
    paymentIntent: PaymentIntent
    backend: {
      vendSaleId: string
    }
  }

  progress: number

  errors: {
    graphql: GraphqlError
    stripe: StripeError
  }
}
type Event =
  | { type: "SET.CART_ITEMS"; cartItems: Items; total: Total }
  | {
      type: "SET.BILLING_DETAILS"
      billingDetails: BillingDetails
    }
  | { type: "BACK" }
  | { type: "NEXT" }
  | { type: "LAYAWAY.TOGGLE" }
  | { type: "SET.LAYAWAY.AMOUNT"; amount: number }
  | { type: "CARD.UPDATED"; valid: boolean }
  | { type: "CONFIRM"; stripe: Stripe; cardElement: StripeCardElement }
  | { type: "done.invoke.hasCartItems"; data: string }
  | { type: "done.invoke.hasPaymentMethod"; data: string }
  | { type: "done.invoke.creatingPaymentMethodWithStripe"; data: PaymentMethod }
  | {
      type: "done.invoke.confirmingCartWithBackend"
      data: ConfirmCheckoutResponse
    }
  | { type: "done.invoke.confirmingCardPaymentWithStripe"; data: PaymentIntent }
  | { type: "ERROR.STRIPE_PAYMENT_METHOD"; error: StripeError }
  | { type: "ERROR.STRIPE_PAYMENT_CONFIRM"; error: StripeError }
  | { type: "ERROR.API_CHECKOUT_CONFIRM"; error: GraphqlError }
  | { type: "ERROR.API_STRIPE_CONFIRM"; error: GraphqlError }

const SESSION_STORAGE_BILLING_DETAILS_KEY = "billing-details"
const DEFAULT_BILLING_DETAILS: BillingDetails = {
  name: "",
  email: "",
  phone: "",
  address: {
    line1: "",
    line2: "",
    postal_code: "",
    state: "",
    city: "",
    country: "US",
  },
}
const ERRORS = {
  "Customer::CartItemMisMatchError":
    "Cart items don't match.  Please start over checkout process again.",
}

export default createMachine<Context, Event>(
  {
    id: "checkout",
    initial: "idle",
    context: {
      cartItemSnapshot: [],
      total: {
        quantity: 0,
        sub: 0,
        grand: 0,
        tax: 0,
      },
      layaway: {
        min: null,
        amount: null,
      },

      billingDetails: DEFAULT_BILLING_DETAILS,

      cardValidity: false,

      stripe: {
        core: null,
        cardElement: null,
        paymentMethod: null,
        clientSecret: null,
        paymentIntent: null,
        backend: {
          vendSaleId: null,
        },
      },

      progress: 0,

      errors: {
        graphql: null,
        stripe: null,
      },
    },
    states: {
      // -----------------precheck------------------
      idle: {
        on: {
          "SET.CART_ITEMS": {
            actions: ["setCartItems"],
            target: "checkingCartItems",
          },
        },
      },
      checkingCartItems: {
        always: [
          {
            cond: "isCartItemsMissing",
            target: "cartItemMissing",
          },
          {
            cond: "isCartBelowStripeMin",
            target: "notEnoughAmount",
          },
          {
            target: "contactInformation.hist",
          },
        ],
      },

      notEnoughAmount: {
        type: "final",
      },

      cartItemMissing: {
        type: "final",
      },
      // -----------------precheck------------------

      contactInformation: {
        initial: "loading",
        states: {
          hist: {
            // this makes it to load only once
            type: "history",
          },
          loading: {
            invoke: {
              id: "contactInformation-loading",
              src: "loadContactInformation",
            },
          },
          loaded: {},
        },
        on: {
          "SET.BILLING_DETAILS": {
            actions: ["setBillingDetails"],
            target: "contactInformation.loaded",
          },
          NEXT: {
            cond: "billingDetailsCompleted",
            target: "layaway",
          },
        },
      },
      layaway: {
        initial: "idle",
        states: {
          idle: {
            always: [
              {
                cond: "isGrandTotalBelowMin",
                target: "grandTotalBelowMin",
              },
              {
                cond: "hasLayawayAmount",
                target: "switchedOn",
              },

              {
                target: "switchedOff",
              },
            ],
          },
          grandTotalBelowMin: {
            on: {
              NEXT: {
                target: "#checkout.payment",
              },
            },
          },
          switchedOn: {
            on: {
              "LAYAWAY.TOGGLE": {
                target: "switchedOff",
              },
              "SET.LAYAWAY.AMOUNT": {
                actions: ["setLayawayAmount"],
                target: "idle",
              },
              NEXT: {
                cond: "hasEnoughLayawayAmount",
                target: "#checkout.payment",
              },
            },
          },
          switchedOff: {
            entry: ["clearLayawayAmount"],
            on: {
              "LAYAWAY.TOGGLE": {
                target: "switchedOn",
              },
              NEXT: {
                target: "#checkout.payment",
              },
            },
          },
        },
        on: {
          BACK: {
            target: "contactInformation.hist",
          },
        },
      },
      payment: {
        entry: ["initProgress"],
        on: {
          BACK: {
            target: "layaway",
          },
          "CARD.UPDATED": {
            actions: ["setCardValidity"],
            target: "cardIsValid",
          },
        },
      },
      cardIsValid: {
        always: [
          {
            cond: "isCardInvalid",
            target: "payment",
          },
        ],

        on: {
          BACK: {
            target: "layaway",
          },
          "CARD.UPDATED": {
            actions: ["setCardValidity"],
            target: "cardIsValid",
          },
          CONFIRM: {
            actions: ["setStripe"],
            target: "confirming",
          },
        },
      },
      confirming: {
        initial: "creatingPaymentMethodWithStripe",
        states: {
          creatingPaymentMethodWithStripe: {
            invoke: {
              id: "creatingPaymentMethodWithStripe",
              src: "createPaymentMethodWithStripe",
              onDone: {
                actions: ["setPaymentMethod", "increaseProgress"],
                target: "confirmingCartWithBackend",
              },
              onError: {
                target: "#checkout.cardIsValid",
              },
            },
          },
          confirmingCartWithBackend: {
            invoke: {
              id: "confirmingCartWithBackend",
              src: "confirmCartWithBackend",
              onDone: {
                actions: ["setBackendCartInfo", "increaseProgress"],
                target: "confirmingCardPaymentWithStripe",
              },
              onError: {
                target: "#checkout.cardIsValid",
              },
            },
          },
          confirmingCardPaymentWithStripe: {
            invoke: {
              id: "confirmingCardPaymentWithStripe",
              src: "confirmCardPaymentWithStripe",
              onDone: {
                actions: ["setPaymentIntent", "increaseProgress"],
                target: "confirmingCardPaymentWithBackend",
              },
              onError: {
                target: "#checkout.cardIsValid",
              },
            },
          },
          confirmingCardPaymentWithBackend: {
            invoke: {
              id: "confirmingCardPaymentWithBackend",
              src: "confirmCardPaymentWithBackend",
              onDone: {
                actions: ["endProgress"],
                target: "#checkout.done",
              },
              onError: {
                target: "#checkout.cardIsValid",
              },
            },
          },
        },
        on: {
          "ERROR.STRIPE_PAYMENT_METHOD": {
            actions: ["setStripeError"],
          },
          "ERROR.STRIPE_PAYMENT_CONFIRM": {
            actions: ["setStripeError"],
          },
          "ERROR.API_CHECKOUT_CONFIRM": {
            actions: ["setGraphqlError"],
          },
          "ERROR.API_STRIPE_CONFIRM": {
            actions: ["setGraphqlError"],
          },
        },
      },
      done: {
        type: "final",
      },
    },
  },
  {
    services: {
      loadContactInformation: (context, event) => async (callback, onReceive) => {
        const value = sessionStorage.getItem(SESSION_STORAGE_BILLING_DETAILS_KEY)
        if (value) {
          // load from session cache so multiple fetch call can be avoided
          callback({
            type: "SET.BILLING_DETAILS",
            billingDetails: JSON.parse(value),
          })
          return
        }

        const response = await fetch(`/api/user/contact-information`)
        const json = await response.json()
        const contactInformation: Generated.ContactInfo = json?.latestContactInformation
        console.log("contactInformation:", contactInformation)

        if (contactInformation) {
          const {
            firstName,
            lastName,
            physicalAddress1,
            physicalAddress2,
            physicalCity,
            physicalState,
            physicalPostcode,
            phone,
            email,
          } = contactInformation
          callback({
            type: "SET.BILLING_DETAILS",
            billingDetails: {
              name: `${firstName} ${lastName}`,
              address: {
                line1: physicalAddress1,
                line2: physicalAddress2,
                city: physicalCity,
                state: physicalState,
                postal_code: physicalPostcode,
                country: "US",
              },
              phone,
              email,
            },
          })
        } else {
          callback({
            type: "SET.BILLING_DETAILS",
            billingDetails: DEFAULT_BILLING_DETAILS,
          })
        }
      },

      createPaymentMethodWithStripe: (context, event) => (callback, onReceive) =>
        new Promise<PaymentMethod>(async (resolve, reject) => {
          const {
            stripe: { core, cardElement },
            billingDetails,
          } = context

          const paymentMethodResult = await core.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails,
          })
          if (paymentMethodResult.error) {
            callback({
              type: "ERROR.STRIPE_PAYMENT_METHOD",
              error: paymentMethodResult.error,
            })
            reject()
            return
          }

          const { paymentMethod } = paymentMethodResult
          resolve(paymentMethod)
        }),
      confirmCartWithBackend: (context, event) => (callback, onReceive) =>
        new Promise<ConfirmCheckoutResponse>(async (resolve, reject) => {
          const cartItems: Generated.CartItemInput[] = context.cartItemSnapshot.map(
            ({
              parentProduct: { id: vendParentProductId },
              variant: { id: vendProductId },
              quantity,
            }) => ({
              vendParentProductId,
              vendProductId,
              quantity,
            })
          )

          const { paymentMethod } = context.stripe
          const checkoutConfirmResponse = await fetch(`/api/user/checkout/confirm`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              paymentMethodId: paymentMethod.id,
              grandTotal: context.total.grand,
              cartItems,
              layawayAmount: context.layaway.amount,
            }),
          })

          const confirmCheckoutJson: ConfirmCheckoutResponse =
            await checkoutConfirmResponse.json()

          if (confirmCheckoutJson.error) {
            callback({
              type: "ERROR.API_CHECKOUT_CONFIRM",
              error: confirmCheckoutJson.error,
            })
            reject()
            return
          }

          resolve(confirmCheckoutJson)
        }),
      confirmCardPaymentWithStripe: (context, event) => (callback, onReceive) =>
        new Promise<PaymentIntent>(async (resolve, reject) => {
          const {
            clientSecret,
            paymentMethod,
            backend: { vendSaleId },
          } = context.stripe

          const cardPaymentResult = await context.stripe.core.confirmCardPayment(
            clientSecret,
            {
              payment_method: paymentMethod.id,
            }
          )
          // -----------------------------------------
          // when the final confirmation fails (like 3D secure, declined etc)
          // -----------------------------------------
          if (cardPaymentResult.error) {
            // -----------------------------------------
            // stripe payment is incomplete status at this point
            // and the vend sale is still active (parked)
            // they both need to be voided
            // -----------------------------------------
            const stripeVoidResponse = await fetch(`/api/user/checkout/void`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                vendSaleId,
                paymentIntentId: cardPaymentResult.error.payment_intent.id,
              }),
            })

            const stripeVoidJson = await stripeVoidResponse.json()
            // show the stripe error after voiding vend sale is done
            callback({
              type: "ERROR.STRIPE_PAYMENT_CONFIRM",
              error: cardPaymentResult.error,
            })

            if (stripeVoidJson.error) {
              callback({
                type: "ERROR.API_STRIPE_CONFIRM",
                error: stripeVoidJson.error,
              })
            }

            reject()
            return
          }

          const { paymentIntent } = cardPaymentResult

          resolve(paymentIntent)
        }),
      confirmCardPaymentWithBackend: (context, event) => (callback, onReceive) =>
        new Promise<void>(async (resolve, reject) => {
          // #########################################
          // very much the last step
          // stripe side is all done
          // just need to change the status of the parked sale in vend
          // #########################################
          const {
            paymentIntent: { id: paymentIntentId },
          } = context.stripe
          const stripeConfirmResponse = await fetch(
            `/api/user/checkout/stripe/${paymentIntentId}/confirm`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                email: context.billingDetails.email,
                origin: document.location.origin,
              }),
            }
          )

          const confirmStripePaymentJson: ConfirmStripePaymentResponse =
            await stripeConfirmResponse.json()

          if (confirmStripePaymentJson.error) {
            callback({
              type: "ERROR.API_STRIPE_CONFIRM",
              error: confirmStripePaymentJson.error,
            })
            reject()
            return
          }

          if (confirmStripePaymentJson.ok) {
            resolve()
          }
        }),
    },
    actions: {
      setCartItems: assign((context, event) => {
        if (event.type != "SET.CART_ITEMS") return

        context.cartItemSnapshot = Array.from(event.cartItems).map(([id, item]) => ({
          ...item,
        }))

        context.total = event.total
        context.layaway.min = Math.ceil(
          event.total.grand * process.env.NEXT_CONFIG_LAYAWAY_INITIAL_PAYMENT_PERCENTAGE
        )
      }),

      setLayawayAmount: assign((context, event) => {
        if (event.type != "SET.LAYAWAY.AMOUNT") return
        context.layaway.amount = event.amount
      }),
      clearLayawayAmount: assign((context, event) => {
        context.layaway.amount = null
      }),

      setBillingDetails: assign((context, event) => {
        if (event.type != "SET.BILLING_DETAILS") return

        context.billingDetails = event.billingDetails
        sessionStorage.setItem(
          SESSION_STORAGE_BILLING_DETAILS_KEY,
          JSON.stringify(event.billingDetails)
        )
      }),
      setCardValidity: assign((context, event) => {
        if (event.type != "CARD.UPDATED") return

        context.cardValidity = event.valid
      }),

      setStripe: assign((context, event) => {
        if (event.type != "CONFIRM") return

        context.stripe.core = event.stripe
        context.stripe.cardElement = event.cardElement
        context.stripe.paymentMethod = null
        context.stripe.clientSecret = null
        context.stripe.backend.vendSaleId = null
      }),

      setPaymentMethod: assign((context, event) => {
        if (event.type != "done.invoke.creatingPaymentMethodWithStripe") return
        context.stripe.paymentMethod = event.data
      }),

      setBackendCartInfo: assign((context, event) => {
        if (event.type != "done.invoke.confirmingCartWithBackend") return

        const {
          data: {
            confirmCheckout: { clientSecret, vendSaleId },
          },
        } = event.data
        context.stripe.clientSecret = clientSecret
        context.stripe.backend.vendSaleId = vendSaleId
      }),

      setPaymentIntent: assign((context, event) => {
        if (event.type != "done.invoke.confirmingCardPaymentWithStripe") return
        context.stripe.paymentIntent = event.data
      }),

      setGraphqlError: assign((context, event) => {
        if (
          event.type == "ERROR.API_CHECKOUT_CONFIRM" ||
          event.type == "ERROR.API_STRIPE_CONFIRM"
        ) {
          context.errors.graphql = event.error
        }
      }),

      setStripeError: assign((context, event) => {
        if (
          event.type == "ERROR.STRIPE_PAYMENT_METHOD" ||
          event.type == "ERROR.STRIPE_PAYMENT_CONFIRM"
        ) {
          context.errors.stripe = event.error
        }
      }),
      initProgress: assign((context, event) => {
        context.progress = 0
      }),
      increaseProgress: assign((context, event) => {
        context.progress += 30
      }),
      doneProgress: assign((context, event) => {
        context.progress = 100
      }),
    },
    guards: {
      isCartItemsMissing: (context, envet) => context.cartItemSnapshot.length == 0,
      billingDetailsCompleted: (context, envet) => {
        const {
          name,
          email,
          phone,
          address: { line1, city, state, postal_code },
        } = context.billingDetails
        return [name, email, phone, line1, city, state, postal_code].every((x) =>
          Boolean(x.trim())
        )
      },
      hasEnoughLayawayAmount: (context, envet) =>
        Boolean(context.layaway.amount)
          ? context.layaway.amount >= context.layaway.min
          : true,
      hasLayawayAmount: (context, envet) => Boolean(context.layaway.amount),
      isCardInvalid: (context, envet) => !context.cardValidity,
      isCartBelowStripeMin: (context, envet) =>
        context.total.grand < process.env.NEXT_CONFIG_STRIPE_MIN_CHARGE_AMOUNT,
      isGrandTotalBelowMin: (context, envet) =>
        context.total.grand < process.env.NEXT_CONFIG_LAYAWAY_MIN_AMOUNT,
    },
  }
)
