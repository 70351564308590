import { Button, Grid, Typography } from "@mui/material"
import Link from "next/link"
import Router from "next/router"
import React from "react"
import { useCart } from "src/machines"
interface Props {}

const UserCheckoutDone: React.FC<Props> = (props) => {
  const { send } = useCart()
  React.useEffect(() => {
    send("CLEAR")
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" data-test-id="payment-done">
          Your order is completed
        </Typography>
      </Grid>
      <Grid item xs={12}>
        Please go to{" "}
        <Link href="/users/orders">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              Router.replace("/users/orders")
            }}
          >
            My Dashboard / My Orders
          </Button>
        </Link>{" "}
        to review the order.
      </Grid>
    </Grid>
  )
}
export default UserCheckoutDone
