import { Send as SendIcon } from "@mui/icons-material"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Snackbar,
} from "@mui/material"
import { useSession } from "next-auth/client"
import React from "react"
import { useForm } from "react-hook-form"
import { TextField } from "src/components/utils/form"
import { useOrder } from "src/machines"
import { EMAIL_REGEX } from "."
interface FormProps {
  email: string
  body: string
}
interface Props {}

const UserOrderInquiry: React.FC<Props> = () => {
  const [session] = useSession()
  const [message, setMessage] = React.useState<string>(null)
  const { state, send } = useOrder()
  const { order } = state.context

  const { handleSubmit, formState, control, errors, reset } = useForm<FormProps>({
    defaultValues: {
      email: session.user.email.toString(),
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    const { email, body } = data

    await fetch(`/api/user/order/${order.id}/inquiry`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        bodyText: body,
      }),
    }).then(({ ok }) => {
      setMessage(ok ? "Email sent" : "System Error")
      if (ok) {
        reset({
          email,
          body: "",
        })
      }
    })
  })

  return (
    <>
      <Snackbar
        open={Boolean(message)}
        onClose={() => {
          setMessage(null)
        }}
        message={message}
        autoHideDuration={5000}
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8}>
          <form onSubmit={onSubmit}>
            <Card>
              <CardHeader
                title="Ask about this order"
                subheader="We will reply to your email address"
              />
              <CardContent>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <TextField
                      name="email"
                      control={control}
                      errors={errors}
                      rules={{
                        required: true,

                        validate: {
                          format: (value) => Boolean(value.match(EMAIL_REGEX)),
                        },
                      }}
                      fullWidth
                      variant="outlined"
                      placeholder="Your Email"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="body"
                      control={control}
                      errors={errors}
                      rules={{ required: true }}
                      multiline={true}
                      rows={10}
                      maxRows={20}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={formState.isSubmitting}
                  startIcon={
                    formState.isSubmitting ? (
                      <CircularProgress color="inherit" size="1.5rem" />
                    ) : (
                      <SendIcon />
                    )
                  }
                >
                  send
                </Button>
              </CardActions>
            </Card>
          </form>
        </Grid>
      </Grid>
    </>
  )
}
export default UserOrderInquiry
