import { useAtom } from "jotai"
import { atomWithMachine } from "jotai/xstate"
import React from "react"
import { globalScope } from "."
import cartMachine, { LOCAL_STORAGE_CART_KEY } from "./cart"
export type { Item } from "./cart"
export { useCart }

const cartAtom = atomWithMachine((get) => cartMachine)

const useCart = () => {
  const [state, send] = useAtom(cartAtom, globalScope)

  React.useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key == LOCAL_STORAGE_CART_KEY) {
        send("SYNC")
      }
    })
  }, [])

  return {
    state,
    send,
  }
}
