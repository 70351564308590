import { Skeleton, Tab, Tabs } from "@mui/material"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import { PRODUCT_CATEGORY_SLUG_PREFIX } from "src/components/Product"
import { useProductLoader } from "src/machines"
interface Props {}

const LayoutCategoryTabs: React.FC<Props> = (props) => {
  const { state, send } = useProductLoader()
  const [value, setValue] = React.useState(-1)
  const router = useRouter()

  React.useEffect(() => {
    if (!state.matches("loaded")) return

    const { vendProducts } = state.context

    const vendProductId = router.query.vendProductId as string
    const categoryIdFromProduct = vendProductId
      ? vendProducts.find(({ id }) => id === vendProductId)?.category?.id
      : null
    const categoryId = categoryIdFromProduct || (router.query.categoryId as string)

    setValue(categories.findIndex(({ id }) => id == categoryId))

    // this doesn't get called on every page change??
    // Router.events.on("routeChangeComplete", (url) => {
    //   console.log("routeChangeComplete:", url)
    // })
  }, [state.matches("loaded")])

  const { categories } = state.context
  const isError = router.pathname == "/_error" || value < 0

  return (
    <>
      {/* TODO: maybe this should be only "loaded"? */}
      {["loaded", "error"].some((name) => state.matches(name)) ? (
        <Tabs
          value={isError ? false : value}
          onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            setValue(newValue)
          }}
          indicatorColor="primary"
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            ".MuiTabs-scrollButtons.Mui-disabled": {
              opacity: 0.3,
            },
          }}
        >
          {categories.map(({ id, name, slug }) => (
            <Link
              key={id}
              href={`/products/categories/${[PRODUCT_CATEGORY_SLUG_PREFIX, slug].join(
                "-"
              )}/${id}/1`}
              passHref
            >
              <Tab label={name} />
            </Link>
          ))}
        </Tabs>
      ) : (
        <Tabs value={false}>
          {[...new Array(8)].map((_, index) => (
            <Tab
              key={index}
              label={
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={100}
                  height={16}
                />
              }
            />
          ))}
        </Tabs>
      )}
    </>
  )
}
export default LayoutCategoryTabs
