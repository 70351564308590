import { Alert, AlertTitle } from "@mui/material"
import Link from "next/link"
import React from "react"
interface Props {}

const WeDoNotShip: React.FC<Props> = (props) => {
  return (
    <Link href="/site/pick-up-location">
      <a target="_blank">
        <Alert severity="error">
          <AlertTitle>Store Pickup Only</AlertTitle>
          We do not ship
        </Alert>
      </a>
    </Link>
  )
}
export default WeDoNotShip
