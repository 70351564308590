import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import React from "react"
import { useErrorMessage } from "src/machines"

interface Props {}
const ErrorDialog: React.FC<Props> = (props) => {
  const { state, send } = useErrorMessage()
  return (
    <Dialog open={state.matches("open")} data-test-id="error-dialog">
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>{state.context.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            send("CLOSE")
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ErrorDialog
