import { css } from "@emotion/react"
import React from "react"
interface Props {
  className: string
  onClick?: () => void
  onMouseOut?: () => void
}

const ProductImageZoomArea: React.FC<Props> = ({
  children,
  className,
  onClick,
  onMouseOut,
}) => {
  React.useEffect(() => {
    // lables somehow goes over the zoom-area
    document.querySelectorAll("label").forEach((label) => {
      label.style.zIndex = "-1"
    })
  }, [])

  return (
    <div
      onMouseOut={onMouseOut}
      onClick={onClick}
      className={className}
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
      `}
    >
      {children}
    </div>
  )
}
export default ProductImageZoomArea
