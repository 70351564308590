import { assign } from "@xstate/immer"
import { Document } from "flexsearch"
import { WritableDraft } from "immer/dist/internal"
import _uniqBy from "lodash/uniqBy"
import { actions, createMachine } from "xstate"
const { log } = actions

type VendProductTagId = VendProductTag["id"]
type categoryId = VendProduct["category"]["id"]

interface BaseFilter {
  keywords: string
  priceFrom: number
  priceTo: number
  categoryId: categoryId
}

export interface Context {
  open: boolean
  products: {
    all: VendProduct[]
    filtered: VendProduct[]
  }
  tags: {
    colors: VendProductTag[]
    categories: VendProductTag[]
    others: Record<string, VendProductTag[]>
  }
  query: BaseFilter & {
    attributes: {
      colorIds: VendProductTagId[]
      categoryIds: VendProductTagId[]
      otherIds: VendProductTagId[]
    }
  }
}

export type Kind = keyof Context["query"]["attributes"]

export type Event =
  | { type: "SETUP"; vendProducts: VendProduct[]; query: Context["query"] }
  | { type: "QUERY" }
  | { type: "CLEAR.FILTER" }
  | { type: "SELECT"; kind: Kind; id: VendProductTagId }
  | { type: "DESELECT"; kind: Kind; id: VendProductTagId }
  | ({
      type: "SET.INPUTS"
    } & Partial<BaseFilter>)
  | { type: "SHOW" }
  | { type: "HIDE" }

const uniqTags = (
  vendProducts: VendProduct[],
  target: "colors" | "categories" | "tags",
  currentlySelectedTagsToShowUserEvenWhenEmpty: VendProductTag[]
) =>
  _uniqBy(
    [
      ...vendProducts.flatMap((vendProduct) => vendProduct[target]).filter((x) => x),
      ...currentlySelectedTagsToShowUserEvenWhenEmpty,
    ],
    ({ id }) => id
  )

const groupByTagGroup = (tags: VendProductTag[]) =>
  tags.reduce<Record<string, VendProductTag[]>>((memo, tag) => {
    if (!memo[tag.group.name]) {
      memo[tag.group.name] = []
    }

    memo[tag.group.name].push(tag)
    return memo
  }, {})

const setup = (context: WritableDraft<Context>, targetVendProducts: VendProduct[]) => {
  const userSelectedColors = context.tags.colors.filter((color) =>
    context.query.attributes.colorIds.includes(color.id)
  )
  const userSelectedCategories = context.tags.categories.filter((category) =>
    context.query.attributes.categoryIds.includes(category.id)
  )
  const userSelectedOthers = Object.values(context.tags.others)
    .flat()
    .filter((other) => context.query.attributes.otherIds.includes(other.id))

  context.tags.colors = uniqTags(targetVendProducts, "colors", userSelectedColors).sort(
    ({ name: a }, { name: b }) => (a < b ? -1 : 1)
  )
  context.tags.categories = uniqTags(
    targetVendProducts,
    "categories",
    userSelectedCategories
  ).sort(({ name: a }, { name: b }) => (a < b ? -1 : 1))
  context.tags.others = groupByTagGroup(
    uniqTags(targetVendProducts, "tags", userSelectedOthers).sort(
      ({ name: a }, { name: b }) => {
        const START_WITH_NUMBERS = /^(\d+)/
        if (START_WITH_NUMBERS.test(a) && START_WITH_NUMBERS.test(b)) {
          // if starting with numbers, sort by the numbers
          const aDash = Number(a.match(START_WITH_NUMBERS)[1])
          const bDash = Number(b.match(START_WITH_NUMBERS)[1])
          return aDash < bDash ? -1 : 1
        } else {
          return a < b ? -1 : 1
        }
      }
    )
  )
}

const MAX_SEARCH_RESULTS = 400

export const defaultQuery: Context["query"] = {
  keywords: "",
  priceFrom: null,
  priceTo: null,
  categoryId: "",

  attributes: {
    colorIds: [],
    categoryIds: [],
    otherIds: [],
  },
}

export default createMachine<Context, Event>(
  {
    id: "search",
    initial: "idle",
    context: {
      open: false,
      products: {
        all: [],
        filtered: [],
      },
      tags: {
        colors: [],
        categories: [],
        others: {},
      },
      query: defaultQuery,
    },

    on: {
      SHOW: {
        actions: ["show"],
      },
      HIDE: {
        actions: ["hide"],
      },
    },

    states: {
      idle: {
        entry: ["initialize"],
        on: {
          SETUP: {
            actions: ["setProducts", "setQuery", "filter"],
            target: "loaded",
          },
        },
      },
      loaded: {
        initial: "idle",
        on: {
          SELECT: {
            actions: ["select"],
            target: "loaded.changing",
          },
          DESELECT: {
            actions: ["deselect"],
            target: "loaded.changing",
          },
          "SET.INPUTS": {
            actions: ["setInputs"],
            target: "loaded.changing",
          },
          SETUP: {
            actions: ["setProducts", "setQuery", "filter"],
            target: "loaded",
          },
        },
        states: {
          idle: {
            after: {
              0: [
                {
                  cond: "hasFilters",
                  target: "readyToQuery",
                },
              ],
            },
          },
          changing: {
            always: [
              {
                cond: "hasFilters",
                target: "readyToQuery",
              },
              {
                target: "idle",
              },
            ],
          },
          readyToQuery: {
            on: {
              "CLEAR.FILTER": {
                actions: ["clearFilter"],
                target: "idle",
              },
              QUERY: {
                actions: ["filter"],
                target: "queried",
              },
            },
          },
          queried: {
            after: {
              0: "idle",
            },
          },
        },
      },
    },
  },
  {
    services: {},
    actions: {
      show: assign((context, event) => {
        context.open = true
      }),

      hide: assign((context, event) => {
        context.open = false
      }),

      setProducts: assign((context, event) => {
        if (event.type != "SETUP") return

        context.products.all = event.vendProducts
        setup(context, context.products.all)
      }),

      initialize: assign((context, event) => {
        context.products.filtered = []
        context.query = defaultQuery
        setup(context, context.products.all)
      }),

      clearFilter: assign((context, event) => {
        context.products.filtered = context.products.all
        context.query = defaultQuery
        setup(context, context.products.all)
      }),

      setQuery: assign((context, event) => {
        if (event.type != "SETUP") return

        const eventQuery = event.query ?? {}
        const temp = { ...defaultQuery }

        context.query = { ...temp, ...eventQuery }
      }),

      setInputs: assign((context, event) => {
        if (event.type != "SET.INPUTS") return

        context.query.keywords = event.keywords ?? context.query.keywords
        context.query.priceFrom = event.priceFrom ?? context.query.priceFrom
        context.query.priceTo = event.priceTo ?? context.query.priceTo
        context.query.categoryId = event.categoryId ?? context.query.categoryId
      }),

      select: assign((context, event) => {
        if (event.type != "SELECT") return

        context.query.attributes[event.kind].push(event.id)
      }),

      deselect: assign((context, event) => {
        if (event.type != "DESELECT") return

        const eliminated = context.query.attributes[event.kind].filter(
          (id) => id != event.id
        )
        context.query.attributes[event.kind] = eliminated
      }),

      filter: assign(async (context, event) => {
        const { keywords, priceFrom, priceTo, categoryId } = context.query
        const { categoryIds, colorIds, otherIds } = context.query.attributes
        let temp = context.products.all

        if (categoryId) {
          temp = temp.filter(({ category }) => category.id == categoryId)
        }

        if (categoryIds.length > 0) {
          temp = temp.filter(({ categories }) => {
            const allIds = categories.map(({ id }) => id)
            return categoryIds.some((id) => allIds.includes(id))
          })
        }

        if (otherIds.length > 0) {
          temp = temp.filter(({ tags }) => tags.some(({ id }) => otherIds.includes(id)))
        }

        if (colorIds.length > 0) {
          temp = temp.filter(({ colors }) =>
            colors.some(({ id }) => colorIds.includes(id))
          )
        }

        const [from, to] = [priceFrom, priceTo].filter((x) => x).sort()

        if (from && to) {
          temp = temp.filter(
            ({ minPriceExcludingTax }) =>
              from <= minPriceExcludingTax && minPriceExcludingTax <= to
          )
        }
        if (from && !to) {
          temp = temp.filter(({ minPriceExcludingTax }) => from <= minPriceExcludingTax)
        }
        if (!from && to) {
          temp = temp.filter(({ minPriceExcludingTax }) => minPriceExcludingTax <= to)
        }

        if (keywords) {
          const index = new Document({
            tokenize: "forward",
            document: {
              id: "id",
              index: ["name", "description"],
            },
          })
          temp.forEach((vendProduct) => {
            index.add({
              ...vendProduct,
            })
          })

          const results = index.search(keywords, MAX_SEARCH_RESULTS)
          const ids = results.flatMap(({ field, result }) => result)
          context.products.filtered = temp.filter(({ id }) => ids.includes(id))
          setup(context, context.products.filtered)
        } else {
          context.products.filtered = temp
          setup(context, temp)
        }
      }),
    },
    guards: {
      hasFilters: (context, event) =>
        [
          context.query.keywords == defaultQuery.keywords,
          context.query.priceFrom == defaultQuery.priceFrom,
          context.query.priceTo == defaultQuery.priceTo,
          context.query.attributes.categoryIds.length == 0,
          context.query.attributes.colorIds.length == 0,
          context.query.attributes.otherIds.length == 0,
        ].some((x) => x == false),
    },
  }
)
