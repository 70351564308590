import { css } from "@emotion/react"
import { PlayCircleFilled as PlayCircleFilledIcon } from "@mui/icons-material"
import { Avatar, Grid, IconButton } from "@mui/material"
import { useSelector } from "@xstate/react"
import React from "react"
import useToggle from "react-use/lib/useToggle"
import { SquaredResponsive } from "src/components/utils/index"
import { useInventory } from "../../atoms"
import ImageDialog from "./ImageDialog"

interface Props {}

const ProductImageDefault: React.FC<Props> = (props) => {
  const service = useInventory()
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)

  const [imageIndex, setImageIndex] = React.useState(0)
  const [initialTabIndex, setInitialTabIndex] = React.useState(0)
  const [dialogOpen, toggle] = useToggle(false)
  React.useEffect(() => {
    setTimeout(() => {
      if (!document.querySelector(".zoom-trigger")) return

      import("drift-zoom").then(({ default: Drift }) => {
        new Drift(document.querySelector(".zoom-trigger"), {
          paneContainer: document.querySelector(".zoom-area"),
          hoverBoundingBox: true,
        })
      })
    }, 10)
  }, [])

  const images = vendProduct?.images ?? []

  return (
    <>
      <Grid container spacing={1}>
        {images.map((url, index) => (
          <Grid item xs={3} sm={2} key={index}>
            <SquaredResponsive>
              <img
                src={url}
                className="fit clickable"
                onMouseEnter={() => {
                  setImageIndex(index)
                }}
              />
            </SquaredResponsive>
          </Grid>
        ))}
        {vendProduct?.hasYoutube && (
          <Grid item xs={3} sm={2}>
            <SquaredResponsive>
              <Grid container justifyContent="center" alignItems="center">
                <IconButton
                  onClick={() => {
                    setInitialTabIndex(0)
                    toggle()
                  }}
                >
                  <Avatar>
                    <PlayCircleFilledIcon />
                  </Avatar>
                </IconButton>
              </Grid>
            </SquaredResponsive>
          </Grid>
        )}
        <Grid item xs={12}>
          <div
            css={css`
              width: 100%;
              cursor: pointer;
              img.zoom-trigger {
                max-width: 100%;
                max-height: 100%;
                display: block;
                margin: 0 auto;
              }
            `}
          >
            <img
              onClick={() => {
                setInitialTabIndex(1)
                toggle()
              }}
              src={images[imageIndex]}
              data-zoom={images[imageIndex]}
              className="zoom-trigger"
            />
          </div>
        </Grid>
      </Grid>

      {dialogOpen && (
        <ImageDialog
          initialIndex={imageIndex}
          initialTabIndex={initialTabIndex}
          onClose={toggle}
        />
      )}
    </>
  )
}
export default ProductImageDefault
