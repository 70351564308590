import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material"
import React from "react"
interface Props {
  vendProduct: VendProduct
}

const CompositeList: React.FC<Props> = ({ vendProduct }) => {
  if (!vendProduct || vendProduct.composites.length == 0) {
    return null
  }

  return (
    <>
      <Typography gutterBottom variant="h6">
        This product contains
      </Typography>
      <Paper>
        <List>
          {vendProduct.composites.map((composite, index) => (
            <ListItem key={index}>
              <a href={composite.imageUrl} target="_blank" rel="noopener noreferrer">
                <ListItemAvatar>
                  <Avatar src={composite.imageUrl} />
                </ListItemAvatar>
              </a>
              <ListItemText primary={composite.name} secondary={composite.variantName} />
              <Avatar>{composite.count}</Avatar>
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  )
}
export default CompositeList
