import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material"
import { Box, Button, Grid, Hidden, Typography } from "@mui/material"
import Link from "next/link"
import React from "react"

export interface Props {
  pageUrlTemplate: string
  currentPage: number
  totalPages: number
}
const Pagination: React.FC<Props> = ({ pageUrlTemplate, currentPage, totalPages }) => {
  const howManyPages = 7
  const howManyPagesOneSide = Math.floor(howManyPages / 2)
  const leftSideMin = Math.max(1, currentPage - howManyPagesOneSide)
  const rightSideMax = Math.min(totalPages, currentPage + howManyPagesOneSide)

  const pages = [...new Array(rightSideMax - leftSideMin + 1)].map(
    (_, index) => leftSideMin + index
  )

  const prev = currentPage - 1
  const next = currentPage + 1
  const prevPage = 1 <= prev ? prev : 1
  const nextPage = next <= totalPages ? next : totalPages

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        {currentPage != 1 && (
          <Link href={[pageUrlTemplate, prevPage].join("/")}>
            <Button startIcon={<ArrowBackIosIcon />} variant="contained" color="inherit">
              Previous
            </Button>
          </Link>
        )}
      </Grid>
      <Hidden smUp>
        <Box p={2}>
          <Typography variant="h4">{currentPage}</Typography>
        </Box>
      </Hidden>
      <Hidden xsDown>
        {pages.map((page) => (
          <Grid item key={page}>
            {page == currentPage ? (
              <Button variant="outlined" disabled>
                {page}
              </Button>
            ) : (
              <Link href={[pageUrlTemplate, page].join("/")}>
                <Button color="inherit" variant="contained">
                  {page}
                </Button>
              </Link>
            )}
          </Grid>
        ))}
      </Hidden>
      <Grid item>
        {currentPage != totalPages && (
          <Link href={[pageUrlTemplate, nextPage].join("/")}>
            <Button endIcon={<ArrowForwardIosIcon />} variant="contained" color="inherit">
              Next
            </Button>
          </Link>
        )}
      </Grid>
    </Grid>
  )
}
export default Pagination
