import { Button, Grid, LinearProgress, Typography } from "@mui/material"
import { Session } from "next-auth"
import React from "react"
import { useForm } from "react-hook-form"
import { TextField } from "src/components/utils/form"
import { useCheckout } from "src/machines"
import { EMAIL_REGEX } from "../Order"

export interface FormProps {
  email: string
  firstName: string
  lastName: string
  line1: string
  line2: string
  city: string
  state: string
  postalCode: string
  phone: string
}
interface Props {
  session: Session
}

const UserCheckoutForm: React.FC<Props> = ({ session }) => {
  const { state, send } = useCheckout()
  const {
    email,
    name,
    phone,
    address: { line1, line2, city, state: stateName, postal_code: postalCode },
  } = state.context.billingDetails
  const [firstName, ...rest] = name.split(" ")
  const lastName = rest.join(" ")
  const isLoaded = state.matches("contactInformation.loaded")

  const {
    reset,
    handleSubmit,
    control,
    errors,
    formState: { submitCount },
  } = useForm<FormProps>({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postalCode: "",
      phone: "",
    },
  })

  React.useEffect(() => {
    if (!isLoaded) return

    reset({
      email: email || session.user.email,
      firstName,
      lastName,
      line1,
      line2,
      city,
      state: stateName,
      postalCode,
      phone,
    })
  }, [isLoaded])

  React.useEffect(() => {
    if (document.querySelectorAll(".Mui-error").length > 0) {
      document.querySelector(".Mui-error").scrollIntoView({ block: "center" })
    }
  }, [submitCount])

  return (
    <form
      onSubmit={handleSubmit(
        async ({
          email,
          firstName,
          lastName,
          line1,
          line2,
          city,
          state,
          postalCode,
          phone,
        }) => {
          send([
            {
              type: "SET.BILLING_DETAILS",
              billingDetails: {
                name: `${firstName} ${lastName}`,
                email,
                phone,
                address: {
                  line1,
                  line2,
                  city,
                  state,
                  postal_code: postalCode,
                  country: "US",
                },
              },
            },
            "NEXT",
          ])
        }
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Contact information</Typography>
          <Typography variant="caption">
            We will use this information to confirm that it is you when you come for a
            pickup.
          </Typography>
        </Grid>
        {isLoaded ? (
          <>
            <Grid item xs={12}>
              <TextField
                name="email"
                control={control}
                errors={errors}
                rules={{
                  required: true,

                  validate: {
                    format: (value) => Boolean(value.match(EMAIL_REGEX)),
                  },
                }}
                fullWidth
                variant="outlined"
                label="Email"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="firstName"
                control={control}
                errors={errors}
                rules={{ required: true }}
                fullWidth
                variant="outlined"
                label="First name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="lastName"
                control={control}
                errors={errors}
                rules={{ required: true }}
                fullWidth
                variant="outlined"
                label="Last name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="line1"
                control={control}
                errors={errors}
                rules={{ required: true }}
                fullWidth
                variant="outlined"
                label="Address 1"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="line2"
                control={control}
                errors={errors}
                fullWidth
                variant="outlined"
                label="Address 2"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="city"
                control={control}
                errors={errors}
                rules={{ required: true }}
                fullWidth
                variant="outlined"
                label="City"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="state"
                control={control}
                errors={errors}
                rules={{ required: true }}
                fullWidth
                variant="outlined"
                label="State"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="postalCode"
                control={control}
                errors={errors}
                rules={{
                  required: true,
                  validate: {
                    // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
                    format: (value) => Boolean(value.match(/^\d{5}(?:-\d{4})?$/)),
                  },
                }}
                fullWidth
                variant="outlined"
                label="Zip"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="phone"
                control={control}
                errors={errors}
                rules={{
                  required: true,
                  validate: {
                    // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
                    format: (value) =>
                      Boolean(value.match(/^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/)),
                  },
                }}
                fullWidth
                variant="outlined"
                label="Phone"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item />
                <Grid item>
                  <Button type="submit" variant="contained" color="primary">
                    next
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography>Loading from the previous order ...</Typography>
            <LinearProgress />
          </Grid>
        )}
      </Grid>
    </form>
  )
}
export default UserCheckoutForm
