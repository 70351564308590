import { Grid, Typography } from "@mui/material"
import { shuffle } from "lodash"
import React from "react"
import { useProductLoader } from "src/machines"
import { ListItem } from ".."
interface Props {}

const smSize = 12 / process.env.NEXT_CONFIG_LIST.ITEMS_PER_ROW

const ProductPromotionYouMayAlsoLikeItems: React.FC<Props> = (props) => {
  const {
    state: {
      context: { vendProducts },
    },
  } = useProductLoader()
  const randomProducts = React.useMemo(() => shuffle(vendProducts).slice(0, 36), [])

  if (vendProducts.length == 0) {
    return null
  }

  return (
    <Grid container spacing={2} className="products list">
      <Grid item xs={12}>
        <Typography variant="h2">You may also like ...</Typography>
      </Grid>
      {randomProducts.map((vendProduct) => (
        <Grid item xs={12} sm={smSize as any} key={vendProduct.id}>
          <ListItem vendProduct={vendProduct} />
        </Grid>
      ))}
    </Grid>
  )
}
export default ProductPromotionYouMayAlsoLikeItems
