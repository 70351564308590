import { css } from "@emotion/react"
import { Search as SearchIcon } from "@mui/icons-material"
import { AppBar, Avatar, Button, Tab, Tabs, Toolbar, useTheme } from "@mui/material"
import Link from "next/link"
import React from "react"
import { useSearch } from "src/machines"
import { Tabs as Categories } from "./Categories"
interface Props {}

const MobileSubHeader: React.FC<Props> = (props) => {
  const theme = useTheme()
  const { state, send } = useSearch()
  const disabled = !state.matches("loaded")

  return (
    <>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar>
          <div
            css={css`
              flex-grow: 1;
              button {
                background-color: white;
                justify-content: space-between;
                text-transform: none;
              }
            `}
          >
            <Button
              variant="outlined"
              disabled={disabled}
              fullWidth
              endIcon={
                <Avatar
                  sx={
                    disabled
                      ? {}
                      : {
                          bgcolor: theme.palette.secondary.main,
                          color: theme.palette.grey[900],
                        }
                  }
                >
                  <SearchIcon />
                </Avatar>
              }
              onClick={() => {
                send("SHOW")
              }}
            >
              {state.context.query.keywords || "Search ..."}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <AppBar position="static" color="default" elevation={0}>
        <Categories />
      </AppBar>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs variant="scrollable">
          <Link href="/products/selections/today's deals" passHref>
            <Tab label="Deals" />
          </Link>
          <Link href="/products/selections/featured items" passHref>
            <Tab label="Featured" />
          </Link>
          <Link href="/products/selections/best sellers" passHref>
            <Tab label="Best Sellers" />
          </Link>
          <Link href="/products/selections/new items" passHref>
            <Tab label="New Items" />
          </Link>
        </Tabs>
      </AppBar>
    </>
  )
}
export default MobileSubHeader
