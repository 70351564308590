import { css } from "@emotion/react"
import { Badge, Card, CardContent, Grid, Typography } from "@mui/material"
import React from "react"
import { useOrder } from "src/machines"
import { decomposePrice } from "src/utils/index"
interface Props {}

const UserOrderLineItems: React.FC<Props> = (props) => {
  const { state, send } = useOrder()
  const { order } = state.context

  const subTotal = decomposePrice(order.subTotal)
  const additionalDiscount = decomposePrice(order.additionalDiscount)
  const totalTax = decomposePrice(order.totalTax)
  const totalPriceIncl = decomposePrice(order.totalPriceIncl)

  return (
    <Card
      css={css`
        .discounted {
          text-decoration: line-through;
          padding-right: 0.5rem;
        }

        .right {
          text-align: right;
        }
      `}
    >
      <CardContent className="products">
        <Grid container spacing={3} alignItems="center">
          {order.lineItems.map((lineItem) => {
            const totalOriginalPrice = decomposePrice(lineItem.totalOriginalPrice)
            const totalPrice = decomposePrice(lineItem.totalPrice)
            return (
              <React.Fragment key={lineItem.id}>
                <Grid item xs={3}>
                  <Badge badgeContent={lineItem.quantity} color="secondary" max={99999}>
                    <img
                      src={lineItem.vendParentProduct.imageThumbnailUrl}
                      className="fit"
                    />
                  </Badge>
                </Grid>
                <Grid item xs={4} sm={6}>
                  {lineItem.vendParentProduct.name}
                  <Typography variant="subtitle2">
                    {lineItem.vendProduct.variantName}
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={3} className="right price">
                  {lineItem.isDiscounted ? (
                    <>
                      was{" "}
                      <Typography className="discounted" component="span">
                        <span className="currency">$</span>
                        <span className="whole">{totalOriginalPrice[0]}</span>
                        <span className="fraction">{totalOriginalPrice[1]}</span>
                      </Typography>
                      <Typography component="span">
                        <span className="currency">$</span>
                        <span className="whole">{totalPrice[0]}</span>
                        <span className="fraction">{totalPrice[1]}</span>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <span className="currency">$</span>
                      <span className="whole">{totalPrice[0]}</span>
                      <span className="fraction">{totalPrice[1]}</span>
                    </>
                  )}
                  {lineItem.note && <pre>{lineItem.note}</pre>}
                </Grid>
              </React.Fragment>
            )
          })}
          <Grid item xs={6}>
            Sub Total
          </Grid>
          <Grid item xs={6} className="right price">
            <span className="currency">$</span>
            <span className="whole">{subTotal[0]}</span>
            <span className="fraction">{subTotal[1]}</span>
          </Grid>

          {0 < order.additionalDiscount && (
            <>
              <Grid item xs={6}>
                Additional Discount
              </Grid>
              <Grid item xs={6} className="right price">
                <span className="currency">$</span>
                <span className="whole">{additionalDiscount[0]}</span>
                <span className="fraction">{additionalDiscount[1]}</span>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            Tax
          </Grid>
          <Grid item xs={6} className="right price">
            <span className="currency">$</span>
            <span className="whole">{totalTax[0]}</span>
            <span className="fraction">{totalTax[1]}</span>
          </Grid>
          <Grid item xs={6}>
            Total
          </Grid>
          <Grid item xs={6} className="right price">
            <span className="currency">$</span>
            <span className="whole">{totalPriceIncl[0]}</span>
            <span className="fraction">{totalPriceIncl[1]}</span>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
export default UserOrderLineItems
