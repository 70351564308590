import { css } from "@emotion/react"
import { useSelector } from "@xstate/react"
import React from "react"
import useToggle from "react-use/lib/useToggle"
import { useIsSmallScreen } from "src/utils/hooks"
import SwiperCore, { Pagination } from "swiper/core"
import { Swiper, SwiperSlide } from "swiper/react"
import { useInventory } from "../../atoms"
import ImageDialog from "./ImageDialog"
SwiperCore.use([Pagination])
interface Props {}

const ProductImageMobile: React.FC<Props> = (props) => {
  const service = useInventory()
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)
  const [state, toggle] = useToggle(false)
  const [imageIndex, setImageIndex] = React.useState(0)
  const isSmallScreen = useIsSmallScreen()

  React.useEffect(() => {
    if (isSmallScreen && Boolean(window.productZoom)) {
      window.productZoom.destroy()
    }
  }, [])

  const images = vendProduct?.images ?? []

  return (
    <>
      <Swiper
        pagination={{
          clickable: true,
        }}
        onActiveIndexChange={(swiper) => {
          setImageIndex(swiper.activeIndex)
        }}
        css={css`
          img {
            width: 100%;
          }

          .youtube {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        `}
      >
        {images.map((url, index) => (
          <SwiperSlide key={index}>
            <img src={url} onClick={toggle} />
          </SwiperSlide>
        ))}
        {vendProduct?.hasYoutube && (
          <SwiperSlide className="youtube">
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${vendProduct?.youtubeId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </SwiperSlide>
        )}
      </Swiper>

      {state && <ImageDialog initialIndex={imageIndex} onClose={toggle} />}
    </>
  )
}
export default ProductImageMobile
