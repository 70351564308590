import { createMachine } from "xstate"

interface Context {}

type Event = { type: "OPEN" } | { type: "CLOSE" }

export default createMachine<Context, Event>({
  initial: "closed",
  context: {},
  states: {
    closed: {
      on: {
        OPEN: {
          target: "open",
        },
      },
    },
    open: {
      on: {
        CLOSE: {
          target: "closed",
        },
      },
    },
  },
})
