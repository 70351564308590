import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import React from "react"
import { Beforeunload } from "react-beforeunload"
import { useForm } from "react-hook-form"
import { NumberTextField } from "src/components/utils/form"
import { useOrder } from "src/machines"
import { toCurrency } from "src/utils/index"
import Processing from "./Processing"
import usePaymentGraphQLError from "./usePaymentGraphQLError"
import usePaymentStripeError from "./usePaymentStripeError"
interface FormProps {
  amount: string
}

interface Props {
  onClose: () => void
}

const UserOrderPaymentDialog: React.FC<Props> = ({ onClose }) => {
  usePaymentGraphQLError()
  usePaymentStripeError()

  const { state, send } = useOrder()

  const {
    order,
    payment: { min: minPayment },
  } = state.context

  const [message, setMessage] = React.useState("")
  const stripe = useStripe()
  const elements = useElements()
  const { handleSubmit, errors, control, formState } = useForm<FormProps>({
    defaultValues: {
      amount: "",
    },
  })

  return (
    <>
      {state.matches("processingPayment") && (
        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
      )}
      <Processing />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={true}
        onClose={onClose}
        data-test-id="payment-dialog"
      >
        <form
          onSubmit={handleSubmit(async ({ amount }) => {
            send({
              type: "PAY.ADDITIONAL",
              stripe,
              cardElement: elements.getElement(CardElement),
              amount: Number.parseFloat(amount),
            })
          })}
        >
          <DialogTitle>Payment</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CardElement
                  onChange={(event) => {
                    setMessage(event.error?.message || "")
                    send({ type: "CARD.UPDATED", valid: event.complete })
                  }}
                  options={{
                    disabled: ["confirmedToPay", "processingPayment"].some((name) =>
                      state.matches(name)
                    ),
                    style: {
                      base: {
                        fontSize: "20px",
                        letterSpacing: "3px",
                        lineHeight: "50px",
                      },
                    },
                    hidePostalCode: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="error">{message}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="h6">
                  <input
                    type="hidden"
                    data-test-id="current-balance"
                    value={order.balance}
                  />
                  Current Balance: {toCurrency(order.balance)}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <NumberTextField
                  fullWidth
                  className="loud-and-clear"
                  name="amount"
                  label="amount"
                  variant="outlined"
                  control={control}
                  errors={errors}
                  rules={{
                    required: true,
                    validate: {
                      minPayment: (value) =>
                        minPayment <= value || `min. payment is $${minPayment}.`,
                    },
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: order.balance,
                      step: 0.01,
                    },
                  }}
                  data-test-id="payment-amount"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <FormControlLabel
              control={
                <Switch
                  onChange={(event) => {
                    send(event.target.checked ? "CONFIRM" : "UNCONFIRM")
                  }}
                  disabled={
                    !["cardIsValid", "confirmedToPay"].some((name) => state.matches(name))
                  }
                  data-test-id="confirm-switch"
                />
              }
              label="Confirm to pay"
            />
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
              disabled={!state.matches("confirmedToPay")}
              data-test-id="confirm-pay"
            >
              pay
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
export default UserOrderPaymentDialog
