import { useRouter } from "next/router"
import React from "react"
import { useCart, useCheckout, useErrorMessage } from "src/machines"

const usePaymentGraphQLError = () => {
  const [shouldRedirectToTop, letUserCheckTheCart] = React.useState(false)
  const [message, set] = React.useState<string>(null)
  const router = useRouter()
  const checkout = useCheckout()
  const cart = useCart()
  const errorMessage = useErrorMessage()
  const { errors } = checkout.state.context

  React.useEffect(() => {
    if (!errors?.graphql?.graphQLErrors) return

    errors.graphql.graphQLErrors.forEach((graphQLError) => {
      if (!graphQLError.extensions) {
        set(errors.graphql.message)
        return
      }

      switch (graphQLError.extensions.errorClass) {
        case "Customer::CartItemMisMatchError":
        case "Customer::LayawayDepositError":
        case "Customer::InvalidStripePaymentMethodId":
        case "Customer::InvalidUserId":
          set(graphQLError.message)
          break

        case "Customer::CartItemInventoryError":
          const { vendParentProductIds } = graphQLError.extensions
          cart.send({ type: "SET.MAX", vendParentProductIds })

          letUserCheckTheCart(true)
          set(graphQLError.message)
          break
      }
    })
  }, [errors.graphql])

  React.useEffect(() => {
    if (!message) return

    errorMessage.send({
      type: "OPEN",
      message,
    })
  }, [message])

  React.useEffect(() => {
    if (!Boolean(message)) return

    if (errorMessage.state.matches("closing")) {
      shouldRedirectToTop ? router.push("/") : router.reload()
    }
  }, [errorMessage.state.matches("closing")])
}
export default usePaymentGraphQLError
