import { useAtom } from "jotai"
import { atomWithMachine } from "jotai/xstate"
import { orderAtom } from "."
import orderMachine from "./order"

// use with jotai's Provider + initialValues={[[atom, initialValue]]}
const atom = atomWithMachine((get) =>
  orderMachine.withContext({
    ...orderMachine.context,
    order: get(orderAtom),
  })
)

const useOrder = () => {
  const [state, send] = useAtom(atom)

  return {
    state,
    send,
  }
}

export { useOrder }
