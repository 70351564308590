import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Link from "next/link"
import React from "react"
import { useIsSmallScreen } from "src/utils/hooks"
import { decomposePrice } from "src/utils/index"
import { PRODUCT_SLUG_PREFIX } from "."
interface Props {
  vendProduct: VendProduct
}

const ProductListItem: React.FC<Props> = ({ vendProduct }) => {
  const theme = useTheme()
  const isSmallScreen = useIsSmallScreen()
  const mdUp = useMediaQuery(theme.breakpoints.up("md"))

  const minPrice = Math.min(
    ...vendProduct.variants.map(({ priceExcludingTax }) => priceExcludingTax)
  )
  const [whole, fraction] = decomposePrice(minPrice)
  const slug = [PRODUCT_SLUG_PREFIX, vendProduct.slug].join("-")
  const href = `/products/${slug}/${vendProduct.id}`

  if (isSmallScreen) {
    return (
      <Card
        elevation={0}
        sx={{
          border: "1px solid #eee",
        }}
      >
        <Grid container>
          <Grid item xs={5}>
            <Link href={href}>
              <a>
                <CardMedia
                  sx={{
                    minHeight: 170,
                    width: "100%",
                  }}
                  image={vendProduct.imageThumbnailUrl}
                />
              </a>
            </Link>
          </Grid>
          <Grid item xs={7}>
            <CardContent>
              <Typography variant="subtitle2" gutterBottom>
                <Link href={href}>
                  <a className="name">{vendProduct.name}</a>
                </Link>
              </Typography>
              {/* <Typography className="price">
                {1 < vendProduct.variants.length && <span className="asLowAs" />}
                <span className="currency">$</span>
                <span className="whole">{whole}</span>
                <span className="fraction">{fraction}</span>
              </Typography> */}
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    )
  }

  return (
    <>
      <Link href={href}>
        <a>
          <img src={vendProduct.imageThumbnailUrl} className="cover" />
        </a>
      </Link>
      <Typography variant="subtitle2" gutterBottom>
        <Link href={href}>
          <a className="name">{vendProduct.name}</a>
        </Link>
      </Typography>
      {/* <Typography className="price">
        {1 < vendProduct.variants.length && <span className="asLowAs" />}
        <span className="currency">$</span>
        <span className="whole">{whole}</span>
        <span className="fraction">{fraction}</span>
      </Typography> */}
    </>
  )
}
export default ProductListItem
