import { css } from "@emotion/react"
import { Button, Dialog, DialogActions, Grid, Snackbar, useTheme } from "@mui/material"
import { useSelector } from "@xstate/react"
import React from "react"
import useToggle from "react-use/lib/useToggle"
import { SquaredResponsive } from "src/components/utils/index"
import SwiperCore, { Pagination, Thumbs, Zoom } from "swiper/core"
import { Swiper, SwiperSlide } from "swiper/react"
import { useInventory } from "../../atoms"
SwiperCore.use([Zoom, Pagination, Thumbs])

interface Props {
  onClose: () => void
  initialIndex: number
}

const ProductImageMobileImageDialog: React.FC<Props> = ({ onClose, initialIndex }) => {
  const service = useInventory()
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null)

  const theme = useTheme()
  const [snackbarState, toggle] = useToggle(true)

  const innerHeight = window.innerHeight

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        css={css`
          .swiper-container {
            width: 100vw;
          }
          .main {
            height: calc(${innerHeight}px * 0.9);
          }
          .thumbs {
            height: calc(${innerHeight}px * 0.1);
          }
        `}
      >
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            back
          </Button>
        </DialogActions>
        <Grid container direction="column">
          <Grid item>
            <Swiper
              initialSlide={initialIndex ?? 0}
              className="main"
              slidesPerView={1}
              loop={true}
              zoom={true}
              pagination={{
                clickable: true,
              }}
              thumbs={{ swiper: thumbsSwiper }}
            >
              {vendProduct.images.map((url, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-zoom-container">
                    <img src={url} className="fit" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid item>
            <Swiper
              onSwiper={setThumbsSwiper}
              className="thumbs"
              spaceBetween={10}
              slidesPerView={6}
              watchSlidesVisibility={true}
              watchSlidesProgress={true}
            >
              {vendProduct.images.map((url, index) => (
                <SwiperSlide key={index}>
                  <SquaredResponsive>
                    <img src={url} className="fit" />
                  </SquaredResponsive>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
        </Grid>
      </Dialog>
      <Snackbar
        message="Double-tap to zoom"
        open={snackbarState}
        onClick={toggle}
        onClose={toggle}
        autoHideDuration={1000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    </>
  )
}
export default ProductImageMobileImageDialog
