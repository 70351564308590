import React from "react"
import { useIsSmallScreen } from "src/utils/hooks"
import Default from "./Default"
import Mobile from "./Mobile"
interface Props {}

const ProductImages: React.FC<Props> = (props) => {
  if (useIsSmallScreen()) {
    return <Mobile />
  }
  return <Default />
}
export default ProductImages
