import { assign } from "@xstate/immer"
import dayjs from "dayjs"
import _uniqBy from "lodash/uniqBy"
import { Generated } from "src/components/Apollo"
import { actions, createMachine } from "xstate"
const { log } = actions

type ID = VendProduct["id"]
export type MemoById = Map<ID, VendProduct>

interface Context {
  lastPublished: Date
  vendProducts: VendProduct[]
  memoById: MemoById
  categories: VendProductCategory[]
  retry: number
}

export type Event =
  | { type: "done.invoke.stale"; data: Generated.VendProductsQuery }
  | { type: "REVALIDATE" }

export const LOCAL_STORAGE_PRODUCTS_KEY = "products" // each local storage should be below 2mb or so (mobile has about 2.5mb limit)
export const LOCAL_STORAGE_PRODUCTS_PER_INDEX = 500 // 10 * 500 = 5000 items
export const LOCAL_STORAGE_LAST_PUBLISHED_KEY = "last-published"

const uniqCategories = (vendProducts: VendProduct[]) =>
  _uniqBy(
    vendProducts.map(({ category }) => category),
    ({ id }) => id
  ).sort(({ name: a }, { name: b }) => {
    if (a < b) {
      return -1
    }

    if (b < a) {
      return 1
    }

    return 0
  })

export default createMachine<Context, Event>(
  {
    initial: "idle",
    context: {
      lastPublished: null,
      vendProducts: [],
      memoById: new Map(),
      categories: [],
      retry: 0,
    },
    states: {
      idle: {
        always: [
          {
            cond: "isServer",
            target: "isServer",
          },
          {
            target: "isBrowser",
          },
        ],
      },
      isServer: {
        type: "final",
      },

      isBrowser: {
        entry: ["loadFromLocalStorage"],
        invoke: {
          id: "isBrowser",
          src: "checkLastPublished",
          onDone: {
            target: "loaded",
          },
          onError: {
            target: "stale",
          },
        },
      },
      stale: {
        invoke: {
          id: "stale",
          src: "load",
          onDone: {
            actions: ["saveToContext"],
            target: "loaded",
          },
          onError: {
            target: "retry",
          },
        },
      },
      retry: {
        exit: ["countUpRetry"],
        after: {
          1000: [
            {
              cond: "shouldRetry",
              target: "stale",
            },
            {
              target: "error",
            },
          ],
        },
      },

      loaded: {
        entry: ["saveToLocalStorage", "memoProductsById"],
        on: {
          REVALIDATE: {
            target: "isBrowser",
          },
        },
      },
      error: {
        on: {
          REVALIDATE: {
            target: "isBrowser",
          },
        },
      },
    },
  },
  {
    services: {
      checkLastPublished: (context, event) =>
        new Promise<void>(async (resolve, reject) => {
          const response = await fetch("/api/products/last-published")
          const json: Generated.VendProductsLastPublishedQuery = await response.json()
          const hasNewData = dayjs(context.lastPublished).isBefore(
            dayjs(json.vendProductsLastPublished)
          )
          hasNewData ? reject() : resolve()
        }),

      load: (context, event) =>
        new Promise<Generated.VendProductsQuery>(async (resolve, reject) => {
          try {
            const response = await fetch("/api/products")
            const json: Generated.VendProductsQuery = await response.json()

            resolve(json)
          } catch (error) {
            console.log("error:", error)
            reject()
          }
        }),
    },
    actions: {
      loadFromLocalStorage: assign((context, event) => {
        const lastPublishedString =
          localStorage.getItem(LOCAL_STORAGE_LAST_PUBLISHED_KEY) ||
          dayjs().subtract(10, "year").toDate().toString()
        context.lastPublished = new Date(lastPublishedString)

        const keys = Object.keys(localStorage)
          .filter((key) => key.startsWith(LOCAL_STORAGE_PRODUCTS_KEY))
          .sort()
        context.vendProducts = keys.flatMap((key) => {
          const productsString = localStorage.getItem(key) || JSON.stringify([])
          return JSON.parse(productsString)
        })
        context.categories = uniqCategories(context.vendProducts)
      }),

      saveToLocalStorage: (context, event) => {
        localStorage.setItem(
          LOCAL_STORAGE_LAST_PUBLISHED_KEY,
          context.lastPublished.toString()
        )

        // remove them all first
        const keys = Object.keys(localStorage)
          .filter((key) => key.startsWith(LOCAL_STORAGE_PRODUCTS_KEY))
          .sort()
        keys.forEach((key) => localStorage.removeItem(key))

        const copied = [...context.vendProducts]
        let index = 0
        while (copied.length) {
          const chunk = copied.splice(0, LOCAL_STORAGE_PRODUCTS_PER_INDEX)
          const key = `${LOCAL_STORAGE_PRODUCTS_KEY}_${index}`
          localStorage.setItem(key, JSON.stringify(chunk))

          index++
        }
      },

      saveToContext: assign((context, event) => {
        if (event.type != "done.invoke.stale") return

        context.lastPublished = new Date(event.data.vendProductsLastPublished)
        // TODO: maybe if I update object by object
        // it will look at the same memory and so objects automatically
        // update by themselves?
        context.vendProducts = event.data.vendProducts
        context.categories = uniqCategories(event.data.vendProducts)
      }),

      memoProductsById: assign((context, event) => {
        context.memoById.clear()

        context.vendProducts.forEach((vendProduct) => {
          vendProduct.variants.forEach((variant) => {
            context.memoById.set(variant.id, variant)
          })
          context.memoById.set(vendProduct.id, vendProduct)
        })
      }),

      countUpRetry: assign((context, event) => {
        context.retry += 1
      }),
    },
    guards: {
      isServer: (context, event) => typeof window == "undefined",
      shouldRetry: (context, event) => context.retry < 3,
    },
  }
)
