import { assign } from "@xstate/immer"
import { createMachine } from "xstate"

interface Context {
  footerVisible: boolean
}
type Event =
  | {
      type: "SET.FOOTER.VISIBILITY"
      visible: boolean
    }
  | {
      type: "OPEN"
    }
  | {
      type: "CLOSE"
    }
export default createMachine<Context, Event>(
  {
    initial: "drawer",
    context: {
      footerVisible: false,
    },
    on: {
      "SET.FOOTER.VISIBILITY": {
        actions: ["setVisibility"],
        target: "checkFooterVisibility",
      },
    },
    states: {
      checkFooterVisibility: {
        always: [
          {
            cond: "isFooterVisible",
            target: "isFooterVisible",
          },
          {
            target: "drawer.hist",
          },
        ],
      },
      isFooterVisible: {},
      drawer: {
        initial: "closed",
        states: {
          hist: {
            type: "history",
          },

          closed: {
            on: {
              OPEN: {
                target: "open",
              },
            },
          },
          open: {
            on: {
              CLOSE: {
                target: "closed",
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      setVisibility: assign((context, event) => {
        if (event.type != "SET.FOOTER.VISIBILITY") return

        context.footerVisible = event.visible
      }),
    },
    guards: {
      isFooterVisible: (context) => context.footerVisible,
    },
  }
)
