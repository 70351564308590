import { ShoppingCart as ShoppingCartIcon } from "@mui/icons-material"
import { Avatar, Badge, IconButton } from "@mui/material"
import { useRouter } from "next/router"
import React from "react"
import { useCart } from "src/machines"
interface Props {}

const LayoutCartButton: React.FC<Props> = (props) => {
  const router = useRouter()
  const { state, send } = useCart()
  const disabled = router.asPath == "/users/checkout"

  if (disabled) {
    return null
  }

  return (
    <IconButton
      onClick={() => {
        send({ type: "SHOW" })
      }}
      size="small"
      data-test-id="cart-button"
    >
      <Badge
        badgeContent={" "}
        color="error"
        variant="dot"
        overlap="circular"
        invisible={!state.matches("hasItems")}
        data-test-id="cart-badge"
      >
        <Avatar className={state.matches("hasItems") ? "cart" : "inherit"}>
          <ShoppingCartIcon />
        </Avatar>
      </Badge>
    </IconButton>
  )
}
export default LayoutCartButton
