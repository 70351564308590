import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { useAtom } from "jotai"
import { globalScope } from "pages/_app"
import React from "react"
import { SHEET_TITLES } from "src/spreadsheet/sheetTitles"
import { fetchedAtAtom, IDs, idsMapAtom } from "."
dayjs.extend(isSameOrAfter)

// export const getStaticProps: GetStaticProps = async (context) => {
//   return {
//     props: {
//       ids,
//       fetchedAt: new Date().toString(),
//     },
//     revalidate: oneHour,
//   }
// compare the fetchedAt on the page load, so that
// sharing the same data crossing different pages will correctly
// return the latest ids
const useUrlIds = (title: SHEET_TITLES, ids: IDs, fetchedAt: Date) => {
  const [fetchedAtMap, setFetchedAt] = useAtom(fetchedAtAtom, globalScope)
  const [idsMap, setIdsMap] = useAtom(idsMapAtom, globalScope)
  const checked = React.useRef(false)

  React.useEffect(() => {
    if (checked.current) return
    checked.current = true

    if (dayjs(fetchedAt).isSameOrAfter(fetchedAtMap.get(title))) {
      fetchedAtMap.set(title, fetchedAt)
      setFetchedAt(fetchedAtMap)

      idsMap.set(title, ids)
      setIdsMap(idsMap)
    }
  }, [ids, fetchedAt])

  return idsMap.get(title)
}

export { useUrlIds }
