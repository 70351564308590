import { css } from "@emotion/react"
import {
  Facebook as FacebookIcon,
  Help as HelpIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Room as RoomIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material"
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material"
import Link from "next/link"
import React from "react"
import theme from "./theme"
interface Props {}

const Footer: React.FC<Props> = (props) => {
  return (
    <footer
      css={css`
        margin-top: 10rem;
        font-size: small;
        .primary {
          font-size: small;
        }
        background-color: ${theme.palette.grey[200]};
        padding: 2rem;

        a {
          color: inherit;
        }

        .cards {
          img {
            height: 50px;
            padding: 5px;
          }
        }
      `}
    >
      <Grid container>
        <Grid item xs={12} sm={4}>
          <List>
            <Link href="/site/pick-up-location">
              <a>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar className="pickup-location-icon">
                      <RoomIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      className: "primary",
                    }}
                    primary="Pickup Loacation"
                  />
                </ListItem>
              </a>
            </Link>
            <Link href="/site/about">
              <a>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar src="/images/favicons/apple-touch-icon.png" />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      className: "primary",
                    }}
                    primary="About Mardi Gras Beads Factory"
                  />
                </ListItem>
              </a>
            </Link>
            <Link href="/site/frequently-asked-questions">
              <a>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <HelpIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      className: "primary",
                    }}
                    primary="Frequently Asked Questions"
                  />
                </ListItem>
              </a>
            </Link>
            <Link href="/site/contact-us">
              <a>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <QuestionAnswerIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      className: "primary",
                    }}
                    primary="Contact Us"
                  />
                </ListItem>
              </a>
            </Link>
          </List>
        </Grid>
        <Grid item xs={12} sm={4}>
          <List>
            <Link href="/site/terms-and-conditions">
              <a>
                <ListItem button>
                  <ListItemText
                    primaryTypographyProps={{
                      className: "primary",
                    }}
                    primary="Terms &amp; Conditions"
                  />
                </ListItem>
              </a>
            </Link>
            <Link href="/site/privacy-policy">
              <a>
                <ListItem button>
                  <ListItemText
                    primaryTypographyProps={{
                      className: "primary",
                    }}
                    primary="Privacy Policy"
                  />
                </ListItem>
              </a>
            </Link>
            <Link href="/site/return-policy">
              <a>
                <ListItem button>
                  <ListItemText
                    primaryTypographyProps={{
                      className: "primary",
                    }}
                    primary="Return Policy"
                  />
                </ListItem>
              </a>
            </Link>
          </List>
        </Grid>
        <Grid item xs={12} sm={4}>
          <List>
            <Link href="https://facebook.com">
              <a>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar className="facebook-icon">
                      <FacebookIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Facebook" />
                </ListItem>
              </a>
            </Link>
            <Link href="https://twitter.com">
              <a>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar className="twitter-icon">
                      <TwitterIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Twitter" />
                </ListItem>
              </a>
            </Link>
            <Link href="https://youtube.com">
              <a>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar className="youtube-icon">
                      <YouTubeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="YouTube" />
                </ListItem>
              </a>
            </Link>
          </List>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          © 2021 mardigrasbeadsfactory.com. All Rights Reserved.
        </Grid>
        <Grid item xs={12} sm={6} className="cards">
          {/* https://support.stripe.com/questions/where-to-find-logos-for-accepted-credit-card-types */}
          <img src="/images/cards/mastercard.png" alt="mastercard" />
          <img src="/images/cards/discover.png" alt="discover" />
          <img src="/images/cards/american-express.png" alt="american-express" />
          <img src="/images/cards/union-pay.png" alt="union-pay" />
          <img src="/images/cards/visa.png" alt="visa" />

          <p>
            <img src="/images/cards/stripe.png" alt="stripe" />
          </p>
        </Grid>
      </Grid>
    </footer>
  )
}
export default Footer
