import { purple, yellow } from "@mui/material/colors"
import { createTheme, responsiveFontSizes } from "@mui/material/styles"

// https://cimdalli.github.io/mui-theme-generator/
const theme = createTheme({
  palette: {
    primary: {
      main: purple[900],
    },
    secondary: {
      main: yellow[500],
    },
  },
})

export default responsiveFontSizes(theme)
