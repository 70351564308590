import { useAtom } from "jotai"
import { atomWithMachine } from "jotai/xstate"
import checkoutMachine from "./checkout"

const checkoutAtom = atomWithMachine((get) => checkoutMachine)

const useCheckout = () => {
  const [state, send] = useAtom(checkoutAtom)

  return {
    state,
    send,
  }
}

export { useCheckout }
