import { Alert, Button, Grid, Typography } from "@mui/material"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import React from "react"
import { Beforeunload } from "react-beforeunload"
import useToggle from "react-use/lib/useToggle"
import { useCheckout } from "src/machines"
import { toCurrency } from "src/utils"
import Processing from "./Processing"
import usePaymentGraphQLError from "./usePaymentGraphQLError"
import usePaymentStripeError from "./usePaymentStripeError"

interface Props {}

const UserCheckoutPayment: React.FC<Props> = (props) => {
  usePaymentGraphQLError()
  usePaymentStripeError()

  const { state, send } = useCheckout()
  const {
    total: { grand: grandTotal },
    layaway: { amount: layawayAmount },
  } = state.context

  const [ready, toggle] = useToggle(false)
  const [message, setMessage] = React.useState("")

  const stripe = useStripe()
  const elements = useElements()

  const onConfirm = async () => {
    send({
      type: "CONFIRM",
      stripe,
      cardElement: elements.getElement(CardElement),
    })
  }

  return (
    <>
      {state.matches("confirming") && (
        <Beforeunload onBeforeunload={(event) => event.preventDefault()} />
      )}
      <Processing />

      <Grid container spacing={2}>
        <Grid item xs={12} data-test-id="stripe-card-element">
          <CardElement
            onReady={() => {
              // CardElement is shaky on its visible transition
              // resulting the elements below get jumped up and down
              setTimeout(() => {
                toggle()
              }, 100)
            }}
            onChange={(event) => {
              setMessage(event.error?.message || "")
              send({ type: "CARD.UPDATED", valid: event.complete })
            }}
            options={{
              style: {
                base: {
                  fontSize: "20px",
                  letterSpacing: "3px",
                  lineHeight: "50px",
                },
              },
              hidePostalCode: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="error">{message}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert color="warning">Payment process might take up to a few minutes.</Alert>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                disabled={state.matches("confirming")}
                variant="contained"
                onClick={() => {
                  send("BACK")
                }}
              >
                back
              </Button>
            </Grid>
            <Grid item>
              {ready && (
                <Button
                  onClick={() => onConfirm()}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={!state.matches("cardIsValid") || state.matches("confirming")}
                  data-test-id="payment-confirm"
                >
                  confirm ({toCurrency(layawayAmount ?? grandTotal)})
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
export default UserCheckoutPayment
