import { Base64 } from "js-base64"
import React from "react"
import { Context, defaultQuery } from "src/machines/search"

const useBase64Params = () => {
  const encode = React.useCallback<(query: Context["query"]) => string>(
    (query) => btoa(JSON.stringify(query)),
    []
  )
  const decode = React.useCallback<(params: string) => Context["query"]>((params) => {
    try {
      return JSON.parse(Base64.atobPolyfill(params))
    } catch (e) {
      return defaultQuery
    }
  }, [])
  return {
    encode,
    decode,
  }
}
export default useBase64Params
