import { assign } from "@xstate/immer"
import { enableMapSet } from "immer"
import { Generated } from "src/components/Apollo"
import { actions, createMachine, interpret } from "xstate"
import productLoader, { MemoById } from "./productLoader"
const { log } = actions
enableMapSet()

type ID = VendProduct["id"]
export type Items = Map<ID, Item>

export interface Item {
  variant: VendProduct
  parentProduct: VendProduct
  quantity: number
  maxQuantity: number
}

export interface Total {
  quantity: number
  sub: number
  grand: number
  tax: number
}

interface Context {
  open: boolean
  items: Items
  merged: boolean
  total: Total
}

type Event =
  | {
      type: "UPSERT"
      variant: VendProduct
      parentProduct?: VendProduct
      quantity: number
    }
  | { type: "DELETE"; id: ID }
  | { type: "SHOW" }
  | { type: "HIDE" }
  | { type: "MERGE" }
  | { type: "CLEAR" }
  | { type: "SYNC" }
  | { type: "SIGN.OUT.CLEAR" }
  | { type: "SET.MAX"; vendParentProductIds: ID[] }
  | { type: "done.invoke.idle"; data: Items }
  | {
      type: "done.invoke.merging"
      data: {
        graphql: Generated.MergeCartMutation
        memoById: MemoById
      }
    }

export const LOCAL_STORAGE_CART_KEY = "cart"
const DEFAULT_MAX_QUANTITY = 9999
// https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts

export default createMachine<Context, Event>(
  {
    id: "cart",
    initial: "idle",
    context: {
      open: false,
      items: new Map(),
      merged: false,
      total: {
        quantity: 0,
        sub: 0,
        grand: 0,
        tax: 0,
      },
    },
    on: {
      UPSERT: {
        actions: ["upsert"],
        target: "updated",
      },

      SHOW: {
        actions: ["show"],
      },
      HIDE: {
        actions: ["hide"],
      },
    },
    states: {
      idle: {
        invoke: {
          id: "idle",
          src: "loadFromStorage",
          onDone: {
            actions: ["receiveFromLocalStorage"],
            target: "updated",
          },
          onError: {
            target: "error",
          },
        },
      },
      empty: {
        on: {
          SYNC: {
            target: "idle",
          },
          MERGE: {
            target: "merging",
          },
        },
      },
      hasItems: {
        on: {
          SYNC: {
            target: "idle",
          },
          MERGE: {
            target: "merging",
          },

          DELETE: {
            actions: ["deleteItem"],
            target: "updated",
          },
          "SET.MAX": {
            actions: ["setMaxQuantity", "saveToLocalStorage"],
            target: "updated",
          },

          CLEAR: {
            actions: ["clearItems"],
            target: "updated",
          },
          "SIGN.OUT.CLEAR": {
            actions: ["clearItems", "calcTotals", "saveToLocalStorage"],
          },
        },

        initial: "canProcess",
        states: {
          canProcess: {
            always: [
              {
                cond: "detectMaxQuantity",
                target: "maxQuantityError",
              },
              {
                cond: "detectStripeMinAmount",
                target: "stripeMinAmountError",
              },
            ],
          },
          stripeMinAmountError: {},
          maxQuantityError: {},
        },
      },
      updated: {
        entry: ["calcTotals"],
        exit: ["saveToLocalStorage", "saveToDatabase"],
        // use after instead of always
        // so that
        // .empty => hasItems
        // .hasItems => hasItems  !!
        // .hasItems => empty
        // can detect state change (to show updated snackbar)
        after: {
          0: [
            {
              cond: "hasItems",
              target: "hasItems",
            },
            {
              target: "empty",
            },
          ],
        },
      },

      merging: {
        invoke: {
          id: "merging",
          src: "merge",
          onDone: {
            actions: ["refreshItems"],
            target: "updated",
          },
          onError: {
            target: "updated",
          },
        },
      },

      error: {
        type: "final",
      },
    },
  },
  {
    services: {
      loadFromStorage: (context, event) =>
        new Promise<Items>((resolve, reject) => {
          const itemsArray =
            JSON.parse(localStorage.getItem(LOCAL_STORAGE_CART_KEY)) ?? []
          const loadedItems: Items = new Map(itemsArray)

          interpret(productLoader)
            .start()
            .onTransition((state) => {
              if (state.matches("error")) {
                reject()
                return
              }

              const items: Items = new Map()
              if (state.matches("loaded")) {
                loadedItems.forEach((item) => {
                  const stillValid =
                    state.context.memoById.has(item.variant.id) &&
                    state.context.memoById.has(item.parentProduct.id)
                  if (!stillValid) return

                  const { quantity } = item
                  const variant = state.context.memoById.get(item.variant.id)
                  const parentProduct = state.context.memoById.get(item.parentProduct.id)

                  const freshItem: Item = {
                    variant,
                    quantity,
                    parentProduct,
                    maxQuantity: item.maxQuantity,
                  }

                  items.set(variant.id, freshItem)
                })

                resolve(items)
              }
            })
        }),

      merge: (context, event) =>
        new Promise<{
          graphql: Generated.MergeCartMutation
          memoById: MemoById
        }>((resolve, reject) => {
          const items = Array.from(context.items).map(([id, item]) => item)
          const cartItems: Generated.CartItemInput[] = items.map(
            ({ parentProduct, variant, quantity }) => ({
              vendParentProductId: parentProduct.id,
              vendProductId: variant.id,
              quantity,
            })
          )

          const body = JSON.stringify(cartItems)

          fetch("/api/cart/merge", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body,
          })
            .then((response) => response.json())
            .then((data) => {
              interpret(productLoader)
                .start()
                .onTransition((state) => {
                  if (state.matches("loaded")) {
                    resolve({
                      graphql: data,
                      memoById: state.context.memoById,
                    })
                  }
                })
            })
            .catch(() => {
              reject()
            })
        }),
    },

    actions: {
      show: assign((context, event) => {
        context.open = true
      }),

      hide: assign((context, event) => {
        context.open = false
      }),

      upsert: assign((context, event) => {
        if (event.type != "UPSERT") return

        const { variant, quantity, parentProduct } = event

        if (context.items.has(variant.id)) {
          context.items.get(variant.id).quantity = quantity
        } else {
          const item: Item = {
            variant,
            quantity,
            parentProduct,
            maxQuantity: DEFAULT_MAX_QUANTITY,
          }

          context.items.set(variant.id, item)
        }
      }),

      deleteItem: assign((context, event) => {
        if (event.type != "DELETE") return

        context.items.delete(event.id)
      }),

      calcTotals: assign((context, event) => {
        const items = Array.from(context.items)
        context.total.quantity = items.reduce(
          (memo, [id, item]) => memo + item.quantity,
          0
        )

        context.total.sub = Number(
          items
            .reduce(
              (memo, [id, item]) => memo + item.variant.priceExcludingTax * item.quantity,
              0
            )
            .toFixed(2)
        )

        context.total.grand = Number(
          items
            .reduce(
              (memo, [id, item]) => memo + item.variant.priceIncludingTax * item.quantity,
              0
            )
            .toFixed(2)
        )

        context.total.tax = context.total.grand - context.total.sub
      }),

      loadFromLocalStorage: assign((context, event) => {
        const items: Items = JSON.parse(localStorage.getItem(LOCAL_STORAGE_CART_KEY))

        items

        context.items = items
      }),

      receiveFromLocalStorage: assign((context, event) => {
        if (event.type != "done.invoke.idle") return

        context.items = event.data
      }),

      saveToLocalStorage: (context, event) => {
        const items = Array.from(context.items)
        localStorage.setItem(LOCAL_STORAGE_CART_KEY, JSON.stringify(items))
      },

      clearItems: assign((context, event) => {
        context.items = new Map()
      }),

      refreshItems: assign((context, event) => {
        if (event.type != "done.invoke.merging") return

        const { graphql, memoById } = event.data
        context.items = new Map()

        // update the current cart with merged items
        graphql.mergeCart.cartItems.forEach(
          ({ vendParentProductId, vendProductId, quantity }) => {
            const parentProduct = memoById.get(vendParentProductId)
            const variant = memoById.get(vendProductId)

            if (![parentProduct, variant].every(Boolean)) return

            const item: Item = {
              variant,
              quantity,
              parentProduct,
              maxQuantity: DEFAULT_MAX_QUANTITY,
            }
            context.items.set(variant.id, item)
          }
        )

        context.merged = true
      }),

      saveToDatabase: (context, event) => {
        if (!context.merged) {
          // if not even once merged, it means there is no
          // valid user behind
          return
        }

        const items = Array.from(context.items).map(([id, item]) => item)
        const cartItems: Generated.CartItemInput[] = items
          .filter(({ quantity }) => quantity > 0)
          .map(({ parentProduct, variant, quantity }) => ({
            vendParentProductId: parentProduct.id,
            vendProductId: variant.id,
            quantity,
          }))

        const body = JSON.stringify(cartItems)

        fetch("/api/cart/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        })
      },

      setMaxQuantity: assign((context, event) => {
        if (event.type != "SET.MAX") return

        context.items.forEach((item) => {
          if (event.vendParentProductIds.includes(item.parentProduct.id)) {
            // don't reveal the actual inventory count
            // instead, there are, at least, NOT enough inventory for the current quantity
            // so setting the quantity as max to let the customer lower the quantity
            item.maxQuantity = item.quantity - 1
          }
        })
      }),
    },
    guards: {
      hasItems: (context, event) => context.items.size > 0,
      detectMaxQuantity: (context, event) =>
        Boolean(
          Array.from(context.items).find(
            ([id, { quantity, maxQuantity }]) => maxQuantity < quantity
          )
        ),
      detectStripeMinAmount: (context, event) =>
        context.total.grand < process.env.NEXT_CONFIG_STRIPE_MIN_CHARGE_AMOUNT,
    },
  }
)
