import { Grid, LinearProgress, Typography } from "@mui/material"
import { useSelector } from "@xstate/react"
import React from "react"
import { FormContext, useForm } from "react-hook-form"
import { useCart, useProductLoader } from "src/machines"
import { mustBeQuantityValue } from "src/utils/index"
import { useInventory } from "../atoms"

interface FormProps {
  variantId: string
  quantity: string
}
interface Props {}

const ProductAddToCart: React.FC<Props> = (props) => {
  const productLoader = useProductLoader()
  const cart = useCart()
  const service = useInventory()
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)
  const inventories = useSelector(service, (state) => state.context.inventories)
  const selectedVariant = useSelector(service, (state) => state.context.selectedVariant)
  const isLoaded = useSelector(service, (state) => state.matches("loaded"))
  const isSoldOut = useSelector(service, (state) => state.matches("soldOut"))

  const methods = useForm<FormProps>({
    defaultValues: {
      variantId: "",
      quantity: "1",
    },
  })
  const { handleSubmit, control, errors, reset } = methods

  React.useEffect(() => {
    if (!isLoaded) return

    reset({
      variantId: selectedVariant?.id,
      quantity: "1",
    })
  }, [isLoaded])

  if (isSoldOut) {
    return (
      <Typography variant="h5" align="center" color="textSecondary">
        out of stock
      </Typography>
    )
  }

  if (!isLoaded) {
    return <LinearProgress />
  }

  return (
    <FormContext {...methods}>
      <form
        className="quiet-label"
        onSubmit={handleSubmit(async (data) => {
          const { variantId, quantity } = data
          const {
            state: {
              context: { items },
            },
          } = cart

          const variant = productLoader.state.context.memoById.get(variantId)
          const thisQuantity = mustBeQuantityValue(quantity)

          const newQuantity = items.has(variantId)
            ? items.get(variantId).quantity + thisQuantity
            : thisQuantity

          cart.send({
            type: "UPSERT",
            variant,
            parentProduct: vendProduct,
            quantity: newQuantity,
          })

          cart.send("SHOW")
        })}
      >
        <Grid container spacing={2} direction="column">
          {/* <Grid item>
            <Price selectedVariant={selectedVariant} />
          </Grid>
          <Grid item>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Units</InputLabel>

              <Controller
                name="variantId"
                control={control}
                rules={{
                  required: true,
                }}
                value={selectedVariant?.id}
                onChange={([event]) => {
                  service.send({ type: "CHANGE", id: event.target.value })
                  return event
                }}
                as={
                  <Select label="Units">
                    {vendProduct.variants.map((variant, index) => {
                      const thisInventory = inventories.find(({ id }) => id == variant.id)

                      return (
                        <MenuItem
                          value={variant.id}
                          key={index}
                          disabled={Boolean(thisInventory?.valid) == false}
                        >
                          {variant.variantName}
                        </MenuItem>
                      )
                    })}
                  </Select>
                }
              />
            </FormControl>
          </Grid> */}
          {/* <Grid item xs={8}>
            <NumberTextField
              name="quantity"
              control={control}
              errors={errors}
              fullWidth
              variant="outlined"
              className="loud-and-clear"
              label="quantity"
              rules={{
                required: true,
                min: 0,
              }}
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
            />
          </Grid>
          <Grid item>
            <Button
              fullWidth
              type="submit"
              color="secondary"
              size="large"
              variant="contained"
            >
              Add to Cart
            </Button>
          </Grid> */}
        </Grid>
      </form>
    </FormContext>
  )
}
export default ProductAddToCart
