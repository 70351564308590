import { useRouter } from "next/router"
import React from "react"
import { useErrorMessage, useOrder } from "src/machines"

const usePaymentGraphQLError = () => {
  const [message, set] = React.useState<string>(null)
  const router = useRouter()
  const { state, send } = useOrder()
  const errorMessage = useErrorMessage()
  const { errors } = state.context
  console.log("errors:", errors)

  React.useEffect(() => {
    if (!errors?.graphql?.graphQLErrors) return

    errors.graphql.graphQLErrors.forEach((graphQLError) => {
      if (!graphQLError.extensions) {
        set(errors.graphql.message)
        return
      }

      switch (graphQLError.extensions.errorClass) {
        case "Customer::VendSaleNotFound":
        case "Customer::PaymentExceeded":
        case "Customer::InvalidPayment":
        case "Customer::InvalidUserId":
          set(graphQLError.message)
          break
      }
    })
  }, [errors.graphql])

  React.useEffect(() => {
    if (!message) return

    errorMessage.send({
      type: "OPEN",
      message,
    })
  }, [message])

  React.useEffect(() => {
    if (errorMessage.state.matches("closing")) {
      router.reload()
    }
  }, [errorMessage.state.matches("closing")])
}
export default usePaymentGraphQLError
