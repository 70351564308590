import { TextField as MuiTextField } from "@mui/material"
import { TextFieldProps } from "@mui/material/TextField"
import React from "react"
import { Controller, EventFunction } from "react-hook-form"
import { dotNotationAwareError } from "."
import ReactHookFormProps from "./ReactHookFormProps"

interface Props {
  onChange?: EventFunction
}
const TextField: React.FC<Props & ReactHookFormProps & TextFieldProps> = (props) => {
  const { errors, name, control, rules, onChange, ...everythingElse } = props
  const { hasError, errorMessage } = dotNotationAwareError({ name, errors })

  return (
    <Controller
      as={<MuiTextField {...everythingElse} error={hasError} helperText={errorMessage} />}
      onChange={onChange}
      name={name}
      rules={rules}
      control={control}
    />
  )
}
export default TextField
