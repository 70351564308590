import { Badge, Grid, Typography } from "@mui/material"
import Link from "next/link"
import React from "react"
import { PRODUCT_SLUG_PREFIX } from "src/components/Product"
import { useCheckout } from "src/machines"
import { decomposePrice } from "src/utils/index"
import Tally from "./Tally"
interface Props {}

const UserCheckoutList: React.FC<Props> = (props) => {
  const { state } = useCheckout()

  const {
    cartItemSnapshot,
    layaway: { amount: layawayAmount },
  } = state.context

  return (
    <Grid container spacing={2} alignItems="center" className="products cart">
      {cartItemSnapshot.map(({ parentProduct, variant, quantity }) => {
        const [whole, fraction] = decomposePrice(variant.priceIncludingTax * quantity)
        const slug = [PRODUCT_SLUG_PREFIX, parentProduct.slug].join("-")
        const url = `/products/${slug}/${parentProduct.id}`

        return (
          <React.Fragment key={variant.id}>
            <Grid item xs={3}>
              <Badge badgeContent={quantity} color="error" max={99999}>
                <Link href={url}>
                  <a>
                    <img src={parentProduct.imageThumbnailUrl} className="fit" />
                  </a>
                </Link>
              </Badge>
            </Grid>
            <Grid item xs={5}>
              <Link href={url}>
                <a>{parentProduct.name}</a>
              </Link>
              <Typography variant="subtitle2">{variant.variantName}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className="price">
                <span className="currency">$</span>
                <span className="whole">{whole}</span>
                <span className="fraction">{fraction}</span>
              </Typography>
            </Grid>
          </React.Fragment>
        )
      })}
      <Grid item xs={12}>
        <Tally />
      </Grid>
    </Grid>
  )
}
export default UserCheckoutList
