import React from "react"
import { SOLID_COLORS } from "../ColorCheckbox"
import ButtonBase, { Props as ButtonBaseProps } from "./ButtonBase"
import BG from "./pre-defined/BG"
import BR from "./pre-defined/BR"
import GRW from "./pre-defined/GRW"
import GW from "./pre-defined/GW"
import PG from "./pre-defined/PG"
import PGG from "./pre-defined/PGG"
import RWB from "./pre-defined/RWB"
import FiveTone from "./tones/FiveTone"
import FourTone from "./tones/FourTone"
import SevenTone from "./tones/SevenTone"
import SixTone from "./tones/SixTone"
import ThreeTone from "./tones/ThreeTone"
import TwoTone from "./tones/TwoTone"

const Tones = {
  2: TwoTone,
  3: ThreeTone,
  4: FourTone,
  5: FiveTone,
  6: SixTone,
  7: SevenTone,
}

const matches = (colors: string[], target: string[]) =>
  target.every((color) => colors.includes(color))

interface Props extends ButtonBaseProps {
  colors: string[]
}
const Builder: React.FC<Props> = (props) => {
  const colors = props.colors.sort()

  if (matches(colors, "green/red/white".split("/").sort())) {
    return <GRW {...props} />
  }
  if (matches(colors, "purple/green/gold".split("/").sort())) {
    return <PGG {...props} />
  }
  if (matches(colors, "red/white/blue".split("/").sort())) {
    return <RWB {...props} />
  }
  if (matches(colors, "black/red".split("/").sort())) {
    return <BR {...props} />
  }
  if (matches(colors, "black/gold".split("/").sort())) {
    return <BG {...props} />
  }
  if (matches(colors, "green/white".split("/").sort())) {
    return <GW {...props} />
  }
  if (matches(colors, "purple/gold".split("/").sort())) {
    return <PG {...props} />
  }

  const Component = Tones[colors.length]

  return (
    <ButtonBase {...props}>
      <Component colors={colors.map((color) => SOLID_COLORS[color])} />
    </ButtonBase>
  )
}
export default Builder
