import { Breadcrumbs, Link as MuiLink, Typography } from "@mui/material"
import Link from "next/link"
import React from "react"
import { PRODUCT_CATEGORY_SLUG_PREFIX } from "."
interface Props {
  vendProduct: VendProduct
}

const ProductBreadcrumbs: React.FC<Props> = ({ vendProduct }) => {
  return (
    <Breadcrumbs>
      <MuiLink component="span">
        <Link href="/">
          <a>TOP</a>
        </Link>
      </MuiLink>
      <MuiLink component="span">
        <Link
          href={`/products/categories/${[
            PRODUCT_CATEGORY_SLUG_PREFIX,
            vendProduct.category.slug,
          ].join("-")}/${vendProduct.category.id}/1`}
        >
          <a>{vendProduct.category.name}</a>
        </Link>
      </MuiLink>
      <Typography>{vendProduct.name}</Typography>
    </Breadcrumbs>
  )
}
export default ProductBreadcrumbs
