import { useAtom } from "jotai"
import { atomWithMachine } from "jotai/xstate"
import searchMachine, { Kind } from "./search"

export type { Kind }
export { useSearch }

const searchAtom = atomWithMachine((get) => searchMachine)

const useSearch = () => {
  const [state, send] = useAtom(searchAtom)

  return {
    state,
    send,
  }
}
