export { globalScope } from "pages/_app"
export * from "./cart.jotai"
export * from "./categoryDrawer.jotai"
export * from "./checkout.jotai"
export * from "./errorMessage.jotai"
export * from "./loading.jotai"
export * from "./order.jotai"
export * from "./productLoader.jotai"
export * from "./search.jotai"

import { atom } from "jotai"
import { Order } from "./order"

export const orderAtom = atom<Order, Order>(null, (get, set, update) => {
  set(orderAtom, update)
})
