import { css } from "@emotion/react"
import { Done as DoneIcon, Payment as PaymentIcon } from "@mui/icons-material"
import {
  Avatar,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  Snackbar,
} from "@mui/material"
import { green, purple } from "@mui/material/colors"
import React from "react"
import TimeAgo from "react-timeago"
import { useOrder } from "src/machines"
import { toCurrency } from "src/utils/index"
import PaymentDialog from "./PaymentDialog"
interface Props {}

const UserOrderPaidHistory: React.FC<Props> = (props) => {
  const [snackBarOpen, setSnackBarOpen] = React.useState(false)
  const { state, send } = useOrder()
  const {
    order,
    payment: { open },
  } = state.context

  React.useEffect(() => {
    if (state.matches("done")) {
      send("CLOSE")
      setSnackBarOpen(true)
    }
  }, [state.value])

  return (
    <>
      <Snackbar
        open={snackBarOpen}
        onClose={() => {
          setSnackBarOpen(false)
        }}
        message="Paid"
        autoHideDuration={3000}
      />

      <Paper>
        <List
          css={css`
            .payment-color {
              color: ${purple.A700};
            }
            .paid-in-full {
              background-color: ${green.A700};
            }
            .paid-in-full-amount {
              color: ${green.A700};
            }
          `}
        >
          <ListSubheader disableSticky>Paid History</ListSubheader>
          {order.payments.map((payment) => (
            <ListItem key={payment.id}>
              <ListItemText
                primary={toCurrency(payment.amount)}
                secondary={
                  <>
                    <TimeAgo date={payment.paymentDate} />,{` ${payment.name}`}
                  </>
                }
                data-test-id="paid-history"
              />
            </ListItem>
          ))}
          <ListItem>
            {state.matches("paidInFull") && (
              <ListItemAvatar>
                <Avatar className="paid-in-full">
                  <DoneIcon />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              inset
              primaryTypographyProps={{
                className: "paid-in-full-amount",
                variant: "h5",
              }}
              primary={toCurrency(order.totalPaid)}
              secondary="Paid Total"
            />
          </ListItem>
          {state.matches("notPaidInFull") && (
            <>
              <ListItem>
                <ListItemText
                  inset
                  primaryTypographyProps={{
                    className: "payment-color",
                    variant: "h5",
                  }}
                  primary={toCurrency(order.balance)}
                  secondary="Balance"
                />
                <IconButton
                  onClick={() => {
                    send("OPEN")
                  }}
                >
                  <PaymentIcon className="payment-color" />
                </IconButton>
              </ListItem>
              <Link className="payment-color">
                <ListItem
                  button
                  onClick={() => {
                    send("OPEN")
                  }}
                >
                  <ListItemText
                    color="prmary"
                    primary="Click here to pay an additional amount"
                  />
                </ListItem>
              </Link>
              <ListItem>
                <ListItemText primary="You can also pay the balance at the pickup location" />
              </ListItem>
            </>
          )}
        </List>
      </Paper>

      {open && (
        <PaymentDialog
          onClose={() => {
            send("CLOSE")
          }}
        />
      )}
    </>
  )
}
export default UserOrderPaidHistory
