import { CacheProvider, EmotionCache } from "@emotion/react"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { Snackbar } from "@mui/material"
import "drift-zoom/dist/drift-basic.css"
import "intersection-observer" // polyfill
import { Provider as JotaiProvider } from "jotai"
import { Provider as NextAuthProvider } from "next-auth/client"
import { AppProps } from "next/app"
import objectFitImages from "object-fit-images"
import React from "react"
import { ErrorDialog, LoadingDialog } from "src/components/Dialogs"
import DefaultLayout, { Welcome as WelcomeLayout } from "src/components/Layout"
import createEmotionCache from "src/components/Layout/createEmotionCache"
import { GateKeeper, useGateKeeper } from "src/components/Restricted"
import { useReloadonNewBuild } from "src/utils/hooks"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "swiper/components/thumbs/thumbs.scss"
import "swiper/components/zoom/zoom.scss"
import "swiper/swiper.scss"

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()
export const globalScope = Symbol()

const App: React.FC<MyAppProps> = ({
  Component,
  pageProps,
  router: { pathname, events },
  emotionCache = clientSideEmotionCache,
}) => {
  const { shouldReload } = useReloadonNewBuild()
  const { requireAuth } = useGateKeeper()

  React.useEffect(() => {
    objectFitImages()
  }, [])

  React.useEffect(() => {
    events.on("routeChangeComplete", () => {
      Array.from(document.querySelectorAll("form")).forEach((form) => {
        form.setAttribute("autocomplete", "off")
      })
    })
    events.on("routeChangeComplete", () => {
      // mobile browser may have a bug
      // delaying it temporary solves it
      setTimeout(() => {
        window.scrollTo({ top: 0 })
      }, 100)
    })
  }, [])

  const welcomePage = (
    <WelcomeLayout>
      <Component {...pageProps} />
    </WelcomeLayout>
  )

  const nonRestrictedPage = (
    <DefaultLayout>
      <Component {...pageProps} />
    </DefaultLayout>
  )

  const restrictedPage = (
    <GateKeeper>
      <DefaultLayout>
        <Component {...pageProps} />
      </DefaultLayout>
    </GateKeeper>
  )

  const isWelcome = pathname == "/welcome"
  const underUser = pathname.startsWith("/users")
  const isRestricted = !isWelcome && requireAuth
  const isNonRestricted = !isWelcome && !requireAuth

  return (
    <NextAuthProvider>
      <JotaiProvider scope={globalScope}>
        <CacheProvider value={emotionCache}>
          {isWelcome && welcomePage}
          {isRestricted && restrictedPage}
          {isNonRestricted && nonRestrictedPage}
          <ErrorDialog />
          <LoadingDialog />

          <Snackbar
            open={!underUser && shouldReload}
            anchorOrigin={{
              horizontal: "left",
              vertical: "top",
            }}
            message="updating..."
            autoHideDuration={3000}
            onClose={() => {
              document.location.reload()
            }}
          />
        </CacheProvider>
      </JotaiProvider>
    </NextAuthProvider>
  )
}

export default App
