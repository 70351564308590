import { Search as SearchIcon } from "@mui/icons-material"
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  useTheme,
} from "@mui/material"
import Router from "next/router"
import React from "react"
import { useBase64Params } from "src/components/utils"
import { useProductLoader, useSearch } from "src/machines"
interface Props {}

const LayoutSearchTextField: React.FC<Props> = (props) => {
  const theme = useTheme()
  const productLoader = useProductLoader()
  const { state, send } = useSearch()
  const { encode } = useBase64Params()
  const disabled = !state.matches("loaded")

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%" }}
      onSubmit={(event) => {
        event.preventDefault()

        if (state.matches("loaded.readyToQuery")) {
          Router.push(`/products/search/${encode(state.context.query)}/1`)
          send({ type: "QUERY" })
        }

        send("SHOW")
      }}
    >
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Select
            displayEmpty
            value={state.context.query.categoryId}
            onChange={(event) => {
              send({
                type: "SET.INPUTS",
                categoryId: event.target.value as string,
              })
            }}
          >
            <MenuItem value="">All</MenuItem>
            {productLoader.state.context.categories.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <InputBase
        fullWidth
        className="loud-and-clear"
        type="search"
        disabled={disabled}
        sx={{
          ml: 1,
          flex: 1,
          ".MuiInputBase-root": {
            backgroundColor: "white",
          },
        }}
        name="keywords"
        placeholder="Search ..."
        value={state.context.query.keywords}
        onChange={(event) => {
          send({
            type: "SET.INPUTS",
            keywords: event.target.value,
          })
        }}
      />
      <IconButton type="submit" disabled={disabled}>
        <Avatar
          sx={
            disabled
              ? {}
              : {
                  bgcolor: theme.palette.secondary.main,
                  color: theme.palette.grey[900],
                }
          }
        >
          <SearchIcon />
        </Avatar>
      </IconButton>
    </Paper>
  )
}
export default LayoutSearchTextField
