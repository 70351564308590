import { css, Global } from "@emotion/react"
import { blue, red, yellow } from "@mui/material/colors"
import React from "react"

interface Props {}

const pickupColor = yellow.A700
const facebookColor = blue[900]
const twitterColor = blue[300]
const youtubeColor = red.A700

export { pickupColor, facebookColor, twitterColor, youtubeColor }

const GlobalCss: React.FC<Props> = (props) => {
  return (
    <Global
      styles={css`
        body,
        #__next {
          min-height: 100vh;
        }

        #__next {
          display: flex;
          flex-flow: column;
        }

        main {
          flex: 1;
        }

        img {
          &.fit {
            max-width: 100%;
            display: inline-block;
            vertical-align: bottom;
          }
          &.cover {
            object-fit: cover;
            /* https://github.com/fregante/object-fit-images */
            font-family: "object-fit: cover;";
          }
        }

        .quiet-label {
          label {
            z-index: -1 !important;
          }
        }

        .products {
          .price {
            .currency,
            .fraction {
              position: relative;
              top: -0.5rem;
              font-size: smaller;
            }
            .whole {
              font-size: 1.2rem;
              font-weight: bolder;
              position: relative;
              top: -0.3rem;
              padding-inline-start: 0.2rem;
              padding-inline-end: 0.2rem;
            }
          }

          &.cart {
            .price {
              .currency,
              .fraction {
                top: -0.6rem;
                font-size: small;
              }
              .whole {
                font-size: 1.3rem;
              }
            }
            input[type="number"] {
              font-weight: bolder;
            }
          }

          &.list {
            .name {
              text-decoration: none;
              color: inherit;
              &:hover {
                text-decoration: underline;
                color: chocolate;
              }
            }

            .price {
              .asLowAs {
                position: relative;
                top: -0.4rem;
                margin-inline-end: 0.5rem;
                &::before {
                  content: "As low as";
                }
              }
            }

            img {
              width: 100%;
              height: 214px;

              &.xsDown {
                height: 300px;
              }

              &.mdUp {
                height: 256px;
              }
            }
          }
        }

        .clickable {
          cursor: pointer;
        }

        div {
          &.push-right {
            flex-grow: 1;
          }
        }

        .loud-and-clear {
          input[type="search"],
          input[type="text"],
          input[type="number"] {
            font-size: x-large;
          }

          &.super {
            input[type="search"],
            input[type="text"],
            input[type="number"] {
              font-size: xx-large;
            }
          }
        }

        .mono {
          font-family: "Roboto Mono", monospace;
          input[type="search"],
          input[type="text"],
          input[type="number"] {
            font-family: "Roboto Mono", monospace;
          }
        }
        input[type="number"] {
          text-align: right;
        }

        .pickup-location-icon {
          background-color: ${pickupColor} !important;
        }
        .facebook-icon {
          background-color: ${facebookColor} !important;
        }
        .twitter-icon {
          background-color: ${twitterColor} !important;
        }
        .youtube-icon {
          background-color: ${youtubeColor} !important;
        }
        .facebook-color {
          color: ${facebookColor} !important;
        }
        .twitter-color {
          color: ${twitterColor} !important;
        }
        .youtube-color {
          color: ${youtubeColor} !important;
        }
      `}
    />
  )
}
export default GlobalCss
