// import { useContext } from "src/components/Layout/Search"
import { useRouter } from "next/router"
import React from "react"
import { useSearch } from "src/machines"
const usePagination = () => {
  const router = useRouter()
  const { state, send } = useSearch()
  const { filtered: filteredVendProducts } = state.context.products

  const [totalPages, setTotalPages] = React.useState(0)
  const [groupByPage, setGroupByPage] = React.useState<Record<number, VendProduct[]>>({})

  const page = parseInt(router.query.page as string)

  // pagination
  React.useEffect(() => {
    setTotalPages(
      Math.ceil(filteredVendProducts.length / process.env.NEXT_CONFIG_LIST.ITEMS_PER_PAGE)
    )

    setGroupByPage(
      filteredVendProducts
        .map<[VendProduct, number]>((vendProduct, index) => [vendProduct, index + 1])
        .reduce((memo, [vendProduct, sequentialNumber]) => {
          if (
            !memo[
              Math.ceil(sequentialNumber / process.env.NEXT_CONFIG_LIST.ITEMS_PER_PAGE)
            ]
          ) {
            memo[
              Math.ceil(sequentialNumber / process.env.NEXT_CONFIG_LIST.ITEMS_PER_PAGE)
            ] = []
          }
          memo[
            Math.ceil(sequentialNumber / process.env.NEXT_CONFIG_LIST.ITEMS_PER_PAGE)
          ].push(vendProduct)
          return memo
        }, {})
    )
  }, [filteredVendProducts])

  let hasResults = true
  if (!page || isNaN(page) || page < 1) {
    hasResults = false
  }
  if (totalPages == 0 || totalPages < page) {
    hasResults = false
  }

  const pageVendProducts = groupByPage[page]
  return {
    totalPages,
    pageVendProducts: pageVendProducts || [],
    ready: state.matches("loaded.readyToQuery"),
  }
}
export default usePagination
