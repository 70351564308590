import Head from "next/head"
import React from "react"
import urlJoin from "url-join"
interface Props {
  path: string
  title: string
  description: string
  imageUrl: string
  // TODO: if the product youtube video, twitter:card should be "player"
}
const twitterAccount = "@..."

const Ogp: React.FC<Props> = ({ path, title, description, imageUrl }) => {
  const url = urlJoin(process.env.NEXT_PUBLIC_SITE_ORIGIN, path)
  const desc = `${process.env.NEXT_PUBLIC_SITE_TITLE} - ${description}`

  return (
    <Head>
      {/* html basics */}
      <meta property="description" content={desc} />
      {/* ogp basics */}
      <meta property="og:type" content={path == "/" ? "website" : "article"} />
      <meta property="og:site_name" content={process.env.NEXT_PUBLIC_SITE_TITLE} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={imageUrl} />
      {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup */}
      {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={twitterAccount} />
      <meta name="twitter:creator" content={twitterAccount} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:image:alt" content={title} />
      {/* facebook */}
      {/* https://developers.facebook.com/docs/sharing/webmasters/ */}
      <meta property="fb:app_id" content="App-ID" />
    </Head>
  )
}
export default Ogp
