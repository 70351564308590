import { Box, Grid } from "@mui/material"
import React from "react"

interface Props {
  colors: string[]
}
const LayoutSearchMultiColorSevenTone: React.FC<Props> = ({ colors }) => {
  const [color7, color6, color5, color4, color3, color2, color1] = colors
  return (
    <Grid container>
      <Grid item xs={4}>
        <Box
          sx={{
            padding: "calc(48px / 6)",
            paddingTop: "calc(48px / 4)",
            paddingBottom: "calc(48px / 4)",
          }}
          bgcolor={color1}
        />
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            padding: "calc(48px / 6)",
            paddingTop: "calc(48px / 4)",
            paddingBottom: "calc(48px / 4)",
          }}
          bgcolor={color2}
        />
      </Grid>
      <Grid item xs={4}>
        <Box
          sx={{
            padding: "calc(48px / 6)",
            paddingTop: "calc(48px / 4)",
            paddingBottom: "calc(48px / 4)",
          }}
          bgcolor={color3}
        />
      </Grid>

      <Grid item xs={3}>
        <Box
          sx={{
            padding: "calc(48px / 8)",
            paddingTop: "calc(48px / 4)",
            paddingBottom: "calc(48px / 4)",
          }}
          bgcolor={color4}
        />
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            padding: "calc(48px / 8)",
            paddingTop: "calc(48px / 4)",
            paddingBottom: "calc(48px / 4)",
          }}
          bgcolor={color5}
        />
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            padding: "calc(48px / 8)",
            paddingTop: "calc(48px / 4)",
            paddingBottom: "calc(48px / 4)",
          }}
          bgcolor={color6}
        />
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            padding: "calc(48px / 8)",
            paddingTop: "calc(48px / 4)",
            paddingBottom: "calc(48px / 4)",
          }}
          bgcolor={color7}
        />
      </Grid>
    </Grid>
  )
}
export default LayoutSearchMultiColorSevenTone
