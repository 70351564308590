import React from "react"
import { SOLID_COLORS } from "../../ColorCheckbox"
import ButtonBase, { Props } from "../ButtonBase"
import ThreeTone from "../tones/ThreeTone"

const LayoutSearchMultiColorPGG: React.FC<Props> = (props) => {
  return (
    <ButtonBase {...props}>
      <ThreeTone
        colors={[SOLID_COLORS.purple, SOLID_COLORS.green, SOLID_COLORS.yellow]}
      />
    </ButtonBase>
  )
}
export default LayoutSearchMultiColorPGG
