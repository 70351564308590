import { Check as CheckIcon } from "@mui/icons-material"
import { Button, Tooltip } from "@mui/material"
import React from "react"
import { useSearch } from "src/machines"

export interface Props {
  color: string
}

const LayoutSearchMultiColorButtonBase: React.FC<Props> = ({ color, children }) => {
  const { state, send } = useSearch()

  const currentlySelected = state.context.query.attributes.colorIds
  const selected = currentlySelected.includes(color)

  const toggle = React.useCallback(() => {
    send({
      type: currentlySelected.includes(color) ? "DESELECT" : "SELECT",
      kind: "colorIds",
      id: color,
    })
  }, [state.context.query.attributes])

  return (
    <Tooltip title={color} placement="top">
      <Button
        onClick={() => {
          toggle()
        }}
      >
        <div className={`multi-color ${color.replace(/\//g, "_")}`}>
          {children}
          {selected && <CheckIcon className="check" />}
        </div>
      </Button>
    </Tooltip>
  )
}
export default LayoutSearchMultiColorButtonBase
