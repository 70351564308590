import { css } from "@emotion/react"
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  ShoppingCart as ShoppingCartIcon,
} from "@mui/icons-material"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Snackbar,
  SwipeableDrawer,
} from "@mui/material"
import { yellow } from "@mui/material/colors"
import Link from "next/link"
import React from "react"
import { WeDoNotShip } from "src/components/User/Checkout"
import { useCart, useProductLoader } from "src/machines"
import { fraction2, toCurrency } from "src/utils/index"
import List from "./List"
interface Props {}
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const LayoutCartDrawer: React.FC<Props> = (props) => {
  const {
    state: {
      context: { vendProducts, memoById },
    },
  } = useProductLoader()
  const { state, send } = useCart()
  const [snackbarState, setSnackbarState] = React.useState(false)

  React.useEffect(() => {
    if (state.matches("updated") && state.context.open == false) {
      // when it's closed, you wanna show it's updated
      // when it's open, the user will see its updates
      setSnackbarState(true)
    }
  }, [state.matches("updated")])

  React.useEffect(() => {
    const items = Array.from(state.context.items)
    items.forEach(([id, _]) => {
      // possibly gone out of the database
      const invalid = !memoById.has(id)
      if (invalid) {
        send({ type: "DELETE", id })
      }
    })
  }, [memoById, JSON.stringify(state.context.items)])

  return (
    <>
      <Snackbar
        message="Cart Updated"
        autoHideDuration={1500}
        open={snackbarState}
        onClose={() => {
          setSnackbarState(false)
        }}
        onClick={() => {
          setSnackbarState(false)
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        data-test-id="cart-snackbar"
      />
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="right"
        open={state.context.open}
        onOpen={() => {}}
        onClose={() => {
          send("HIDE")
        }}
        data-test-id="cart-drawer"
      >
        <Box
          sx={{
            height: "100vh",
            overflowY: "scroll",
          }}
        >
          <Card
            elevation={0}
            css={css`
              padding-bottom: 40vh;
              width: 400px;
              max-width: 100vw;
              .avatar {
                background-color: ${yellow.A700};
              }
            `}
          >
            <CardHeader
              avatar={
                <Avatar className="avatar">
                  <ShoppingCartIcon />
                </Avatar>
              }
              title="Your Cart"
              subheader={`${fraction2.format(state.context.total.quantity)} ${
                state.context.total.quantity > 1 ? "items" : "item"
              } in cart`}
              action={
                <IconButton
                  onClick={() => {
                    send("HIDE")
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              }
            />

            <CardContent>
              <List />

              <WeDoNotShip />
            </CardContent>

            <CardActions>
              <Link href="/users/checkout">
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={!state.matches("hasItems.canProcess")}
                  onClick={() => {
                    send("HIDE")
                  }}
                  data-test-id="checkout"
                >
                  Check out
                  {state.context.total.grand <
                    process.env.NEXT_CONFIG_STRIPE_MIN_CHARGE_AMOUNT &&
                    `(requires over ${toCurrency(
                      process.env.NEXT_CONFIG_STRIPE_MIN_CHARGE_AMOUNT
                    )})`}
                  <ArrowForwardIcon />
                </Button>
              </Link>
            </CardActions>
            <CardActions>
              <Button
                fullWidth
                size="large"
                onClick={() => {
                  send("HIDE")
                }}
              >
                <ArrowBackIcon /> Back to shopping
              </Button>
            </CardActions>
          </Card>
        </Box>
      </SwipeableDrawer>
    </>
  )
}
export default LayoutCartDrawer
