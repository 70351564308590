import { CssBaseline, ThemeProvider } from "@mui/material"
import React from "react"
import GlobalCss from "./GlobalCss"
import Heads from "./Heads"
import theme from "./theme"

interface Props {}

const LayoutWelcome: React.FC<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Heads />
      <CssBaseline />
      <GlobalCss />
      <main>{children}</main>
    </ThemeProvider>
  )
}
export default LayoutWelcome
