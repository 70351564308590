// to avoid importing "google-spreadsheet" on client side
// this file is separated

export const SheetTitles = [
  "featured items",
  "today's deals",
  "best sellers",
  "new items",
] as const
export type SHEET_TITLES = typeof SheetTitles[number]
