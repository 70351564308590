import {
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from "@mui/material"
import React from "react"
import { useForm } from "react-hook-form"
import { NumberTextField } from "src/components/utils/form"
import { useCheckout } from "src/machines"
import { toCurrency } from "src/utils/index"

interface FormProps {
  amount: string
}
interface Props {}

const UserCheckoutLayaway: React.FC<Props> = (props) => {
  const { state, send } = useCheckout()
  const {
    total: { grand: grandTotal },
    layaway: { amount: layawayAmount, min: minLayawayAmount },
  } = state.context
  console.log("state.value:", state.value)
  const { handleSubmit, control, errors, reset } = useForm<FormProps>({
    defaultValues: {
      amount: (layawayAmount || minLayawayAmount).toString(),
    },
  })

  const inputProps = state.matches("layaway.switchedOn")
    ? {
        min: minLayawayAmount,
        max: grandTotal,
        step: 0.01,
      }
    : {}

  const canLayaway = !state.matches("layaway.grandTotalBelowMin")

  return (
    <form
      onSubmit={handleSubmit(async ({ amount }) => {
        send([{ type: "SET.LAYAWAY.AMOUNT", amount: Number(amount) }, "NEXT"])
      })}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          {canLayaway ? (
            <Typography>
              Layaway initial payment minimum is{" "}
              {process.env.NEXT_CONFIG_LAYAWAY_INITIAL_PAYMENT_PERCENTAGE * 100}% of the
              total : [ {toCurrency(minLayawayAmount)} or more ]
            </Typography>
          ) : (
            <Typography
              variant="h5"
              gutterBottom
              color="error"
              data-test-id="layaway-min-amount"
            >
              Layaway requires the minimum of{" "}
              {toCurrency(process.env.NEXT_CONFIG_LAYAWAY_MIN_AMOUNT)} total
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                disabled={!canLayaway}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  send("LAYAWAY.TOGGLE")
                }}
                checked={state.matches("layaway.switchedOn")}
                data-test-id="layaway-switch"
              />
            }
            label="use layaway plan"
          />
        </Grid>
        <Grid item xs={6}>
          <NumberTextField
            name="amount"
            className="loud-and-clear"
            fullWidth
            disabled={!state.matches("layaway.switchedOn")}
            control={control}
            errors={errors}
            InputProps={{
              inputProps,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="outlined"
            label="Initial Payment"
            data-test-id="layaway-amount"
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  send("BACK")
                }}
              >
                back
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
export default UserCheckoutLayaway
