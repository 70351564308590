import { css } from "@emotion/react"
import { AppBar, Box, Toolbar } from "@mui/material"
import { green, yellow } from "@mui/material/colors"
import dynamic from "next/dynamic"
import Link from "next/link"
import React from "react"
import { useIsSmallScreen } from "src/utils/hooks"
import { Drawer as CartDrawer } from "./Cart"
import { Drawer as CategoryDrawer } from "./Categories"
import MobileSubHeader from "./MobileSubHeader"
import { Drawer as SearchDrawer, TextField as SearchTextField } from "./Search"
import SubHeader from "./SubHeader"
const User = dynamic(() => import("./User"), { ssr: false })
const Cart = dynamic(() => import("./Cart"), { ssr: false })

interface Props {}
const LayoutHeader: React.FC<Props> = (props) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <>
      <AppBar
        elevation={1}
        position="static"
        css={css`
          .logo {
            height: ${isSmallScreen ? 40 : 60}px;
            margin: 0.5rem;
          }
          .cart {
            background-color: ${yellow.A700};
          }
          .user {
            &.signed-in {
              background-color: ${green.A700};
            }
          }
        `}
      >
        <Toolbar>
          <Link href="/">
            <a>
              <img
                className="logo"
                src={`/logos/${isSmallScreen ? "white-mobile.png" : "white-pc.png"}`}
              />
            </a>
          </Link>

          {!isSmallScreen && (
            <Box
              sx={{
                width: "100%",
                margin: "0 2rem",
              }}
            >
              <SearchTextField />
            </Box>
          )}

          <div className="push-right" />
          {/* <User />
          <Cart /> */}
        </Toolbar>
      </AppBar>
      {isSmallScreen ? <MobileSubHeader /> : <SubHeader />}

      <CategoryDrawer />
      <SearchDrawer />
      <CartDrawer />
    </>
  )
}
export default LayoutHeader
