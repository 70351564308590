import { css, Global } from "@emotion/react"
import { Box, Grid, Step, StepLabel, Stepper, useTheme } from "@mui/material"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Session } from "next-auth"
import Router from "next/router"
import React from "react"
import { useCart, useCheckout } from "src/machines"
import { WeDoNotShip } from "."
import Done from "./Done"
import Form from "./Form"
import Layaway from "./Layaway"
import List from "./List"
import Payment from "./Payment"

interface Props {
  session: Session
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)

const STEP_LABELS = ["Contact Information", "Choosing Layaway", "Payment", "Done"]

const STEPS = [
  ["contactInformation"],
  ["layaway"],
  ["payment", "cardIsValid", "confirming"],
  ["done"],
]
const UserCheckout: React.FC<Props> = ({ session }) => {
  const theme = useTheme()

  const cart = useCart()
  const checkout = useCheckout()

  React.useEffect(() => {
    if (!["empty", "hasItems"].some((name) => cart.state.matches(name))) return

    checkout.send({
      type: "SET.CART_ITEMS",
      cartItems: cart.state.context.items,
      total: cart.state.context.total,
    })
  }, [cart.state.value.toString()])

  React.useEffect(() => {
    window.scrollTo(0, 0)

    if (checkout.state.matches("cartItemMissing")) {
      Router.replace("/your-cart-is-empty")
    }
    if (checkout.state.matches("notEnoughAmount")) {
      Router.replace("/can-not-checkout")
    }
  }, [checkout.state.value])

  const step = STEPS.findIndex((stateNames) =>
    stateNames.some((name) => checkout.state.matches(name))
  )

  return (
    <Grid
      container
      spacing={4}
      css={css`
        .steps {
          padding-bottom: 5rem;
        }
        .list {
          padding-bottom: 5rem;
          padding-top: 2rem;
          border-left: 1px solid ${theme.palette.grey[300]};
          background-color: ${theme.palette.grey[100]};
        }
      `}
    >
      <Global
        styles={css`
          /* makes sure there is no blank gap between this grid and footer */
          footer {
            margin-top: 0 !important;
          }
        `}
      />
      <Grid item xs={12} sm={7} className="steps">
        <WeDoNotShip />

        <Box mt={2} mb={2}>
          <Stepper activeStep={step} alternativeLabel>
            {STEP_LABELS.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {step == 0 && <Form session={session} />}
        {step == 1 && <Layaway />}
        {step == 2 && (
          <Elements stripe={stripePromise}>
            <Payment />
          </Elements>
        )}
        {step == 3 && <Done />}
      </Grid>
      <Grid item xs={12} sm={5} className="list">
        <List />
      </Grid>
    </Grid>
  )
}

export default UserCheckout
