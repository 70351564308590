import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import React from "react"
import { useLoading } from "src/machines"
interface Props {}

const LoadingIndicator: React.FC<Props> = (props) => {
  const { state } = useLoading()
  return (
    <Dialog open={state.matches("open")}>
      <DialogTitle>Loading</DialogTitle>
      <DialogContent>
        <Typography align="center" component="div">
          <CircularProgress />
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
export default LoadingIndicator
