import { Grid, Typography } from "@mui/material"
import React from "react"
import { useCheckout } from "src/machines"
import { decomposePrice } from "src/utils/index"
interface Props {}

const Tally: React.FC<Props> = (props) => {
  const { state } = useCheckout()

  const { total, cartItemSnapshot, layaway } = state.context

  if (cartItemSnapshot.length == 0) {
    return null
  }

  const sub = decomposePrice(total.sub)
  const tax = decomposePrice(total.tax)
  const grand = decomposePrice(total.grand)
  const layawayAmount = decomposePrice(layaway.amount)
  const balance = decomposePrice(total.grand - layaway.amount)

  return (
    <>
      <Grid container spacing={2} className="products">
        <Grid item xs={6}>
          Sub Total
        </Grid>
        <Grid item xs={6}>
          <Typography align="right" className="price">
            <span className="currency">$</span>
            <span className="whole">{sub[0]}</span>
            <span className="fraction">{sub[1]}</span>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          Sales Tax
        </Grid>
        <Grid item xs={6}>
          <Typography align="right" className="price">
            <span className="currency">$</span>
            <span className="whole">{tax[0]}</span>
            <span className="fraction">{tax[1]}</span>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Grand Total</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="right" className="price">
            <span className="currency">$</span>
            <span className="whole">{grand[0]}</span>
            <span className="fraction">{grand[1]}</span>
          </Typography>
        </Grid>

        {Boolean(layaway.amount) && (
          <>
            <Grid item xs={6}>
              Layaway Initial Payment
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" className="price">
                <span className="currency">$</span>
                <span className="whole">{layawayAmount[0]}</span>
                <span className="fraction">{layawayAmount[1]}</span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">Balance</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" className="price">
                <span className="currency">$</span>
                <span className="whole">{balance[0]}</span>
                <span className="fraction">{balance[1]}</span>
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
export default Tally
