import { Grid, Typography } from "@mui/material"
import { useSelector } from "@xstate/react"
import Fuse from "fuse.js"
import _shuffle from "lodash/shuffle"
import React from "react"
import { useProductLoader } from "src/machines"
import { useInventory } from "../atoms"
import ListItem from "../ListItem"
interface Props {}

const options: Fuse.IFuseOptions<VendProduct> = {
  findAllMatches: true,
  threshold: 0.5,
  ignoreLocation: true,
  keys: ["name", "category.name", "description", { name: "categories.name", weight: 2 }],
}

const smSize = 12 / process.env.NEXT_CONFIG_LIST.ITEMS_PER_ROW

const ProductPromotionRelatedItems: React.FC<Props> = (props) => {
  const [relatedProducts, set] = React.useState<VendProduct[]>([])
  const service = useInventory()
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)
  const {
    state: {
      context: { vendProducts },
    },
  } = useProductLoader()

  React.useEffect(() => {
    const keywords = `${vendProduct.description} ${
      vendProduct.category.name
    } ${vendProduct.categories.map(({ name }) => name).join(" ")}`
    console.log("keywords:", keywords)

    set(
      new Fuse(vendProducts, options)
        .search(keywords)
        .map<VendProduct>(({ item }) => item)
        .filter(({ id }) => id != vendProduct.id)
        .slice(0, 36)
    )
  }, [])

  if (relatedProducts.length == 0) {
    return null
  }

  return (
    <Grid container spacing={2} className="products list">
      <Grid item xs={12}>
        <Typography variant="h2">Related Items</Typography>
      </Grid>
      {_shuffle(relatedProducts)
        .slice(0, 12)
        .map((vendProduct) => (
          <Grid item xs={12} sm={smSize as any} key={vendProduct.id}>
            <ListItem vendProduct={vendProduct} />
          </Grid>
        ))}
    </Grid>
  )
}

export default ProductPromotionRelatedItems
