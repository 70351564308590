import { Button } from "@mui/material"
import React from "react"
import { Kind, useSearch } from "src/machines"
// import { useContext } from "."
interface Props {
  id: string
  kind: Kind
}

const CheckboxLikeButton: React.FC<Props> = ({ id, kind, children }) => {
  const { state, send } = useSearch()

  const currentlySelected = state.context.query.attributes[kind]
  const selected = currentlySelected.includes(id)

  const toggle = React.useCallback(() => {
    send({
      type: currentlySelected.includes(id) ? "DESELECT" : "SELECT",
      kind,
      id,
    })
  }, [state.context.query.attributes])

  return (
    <Button
      onClick={() => {
        toggle()
      }}
      variant={selected ? "contained" : "text"}
      color="inherit"
    >
      {children}
    </Button>
  )
}
export default CheckboxLikeButton
