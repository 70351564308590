import { css } from "@emotion/react"
import { Breadcrumbs, Grid, Link as MuiLink, Typography } from "@mui/material"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Link from "next/link"
import React from "react"
import { useOrder } from "src/machines"
import Inquiry from "./Inquiry"
import LineItems from "./LineItems"
import PaidHistory from "./PaidHistory"
import Pickup from "./Pickup"
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)
interface Props {}

const UserOrderForm: React.FC<Props> = (props) => {
  const { state, send } = useOrder()
  const { order } = state.context

  const invoiceNumber = `Invoice #${order.invoiceNumber}`
  const isVoided = state.matches("voided")

  return (
    <Grid
      container
      spacing={2}
      className={isVoided ? "voided" : ""}
      css={css`
        &.voided {
          .invoice-number {
            text-decoration: line-through;
          }

          .paid-in-full-amount {
            text-decoration: line-through;
            color: inherit;
          }
        }
      `}
    >
      <Grid item xs={12}>
        <Breadcrumbs>
          <MuiLink component="span">
            <Link href="/users">
              <a>Dashboard</a>
            </Link>
          </MuiLink>
          <MuiLink component="span">
            <Link href="/users/orders">
              <a>Orders</a>
            </Link>
          </MuiLink>
          <Typography>{invoiceNumber}</Typography>
        </Breadcrumbs>
      </Grid>

      <Grid item xs={12}>
        {isVoided && <Typography variant="h4">(VOIDED)</Typography>}
        <Typography variant="h3" className="invoice-number">
          {invoiceNumber}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={7}>
        <LineItems />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Elements stripe={stripePromise}>
          <PaidHistory />
        </Elements>
      </Grid>

      {!isVoided && (
        <Grid item xs={12}>
          <Pickup />
        </Grid>
      )}

      <Grid item xs={12}>
        <Inquiry />
      </Grid>
    </Grid>
  )
}
export default UserOrderForm
