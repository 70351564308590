import { useRouter } from "next/router"
import React from "react"
import { useGateKeeper } from "."
interface Props {}

const GateKeeper: React.FC<Props> = ({ children }) => {
  const [okToGo, set] = React.useState(false)
  const router = useRouter()
  const { requireAuth, notLoggedIn, loggedIn } = useGateKeeper()

  React.useEffect(() => {
    if (!notLoggedIn && !loggedIn) {
      return
    }

    if (notLoggedIn && requireAuth) {
      router.replace("/welcome")
      return
    }

    if (loggedIn) {
      set(true)
    }
  }, [requireAuth, notLoggedIn, loggedIn])

  if (okToGo) {
    return <>{children}</>
  }

  return null
}
export default GateKeeper
