import { FormContextValues } from "react-hook-form/dist/contextTypes"
import NumberTextField from "./NumberTextField"
import TextField from "./TextField"

// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
const get = (obj, path, defaultValue?) => {
  const result = String.prototype.split
    .call(path, /[,[\].]+?/)
    .filter(Boolean)
    .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj)
  return result === undefined || result === obj ? defaultValue : result
}

interface Props extends Pick<FormContextValues, "errors"> {
  name: string
}
const dotNotationAwareError = ({ name, errors }: Props) => {
  const error = get(errors, name)
  return {
    hasError: error ? true : false,
    errorMessage: error && error.message,
  }
}

export { NumberTextField, TextField, dotNotationAwareError }
