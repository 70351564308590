import React from "react"
import { SOLID_COLORS } from "../../ColorCheckbox"
import ButtonBase, { Props } from "../ButtonBase"
import TwoTone from "../tones/TwoTone"

const LayoutSearchMultiColorPG: React.FC<Props> = (props) => {
  return (
    <ButtonBase {...props}>
      <TwoTone colors={[SOLID_COLORS.purple, SOLID_COLORS.yellow]} />
    </ButtonBase>
  )
}
export default LayoutSearchMultiColorPG
