import { Box, Grid } from "@mui/material"
import React from "react"

interface Props {
  colors: string[]
}
const LayoutSearchMultiColorTwoTone: React.FC<Props> = ({ colors }) => {
  const [color3, color2, color1] = colors
  return (
    <Grid container direction="column">
      <Grid item>
        <Box
          sx={{
            padding: "calc(48px / 6)",
          }}
          bgcolor={color1}
        />
      </Grid>
      <Grid item>
        <Box
          sx={{
            padding: "calc(48px / 6)",
          }}
          bgcolor={color2}
        />
      </Grid>
      <Grid item>
        <Box
          sx={{
            padding: "calc(48px / 6)",
          }}
          bgcolor={color3}
        />
      </Grid>
    </Grid>
  )
}
export default LayoutSearchMultiColorTwoTone
