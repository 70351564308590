import { css } from "@emotion/react"
import React from "react"

interface Props {}
const SquaredResponsive: React.FC<Props> = ({ children }) => {
  return (
    <div
      css={css`
        display: block;
        position: relative;
        padding-top: 100%;

        > * {
          position: absolute;
          display: block;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
        }
        img {
          object-fit: cover;
          /* https://github.com/fregante/object-fit-images */
          font-family: "object-fit: cover;";
        }
      `}
    >
      {children}
    </div>
  )
}
export default SquaredResponsive
