import { Alert, Dialog, DialogContent, DialogTitle, LinearProgress } from "@mui/material"
import React from "react"
import { useCheckout } from "src/machines"
interface Props {}

const Processing: React.FC<Props> = (props) => {
  const { state, send } = useCheckout()
  const { progress } = state.context
  const [buffer, set] = React.useState(0)

  React.useEffect(() => {
    const id = setInterval(() => {
      set((prev) => prev + 2)
    }, 500)

    return () => {
      clearInterval(id)
      set(0)
    }
  }, [progress])

  return (
    <Dialog open={state.matches("confirming")}>
      <DialogTitle>Processing ...</DialogTitle>
      <DialogContent>
        <p>
          <LinearProgress
            variant="buffer"
            valueBuffer={progress + buffer}
            value={progress}
          />
        </p>
        <Alert color="warning">Payment process might take up to a few minutes.</Alert>
      </DialogContent>
    </Dialog>
  )
}
export default Processing
