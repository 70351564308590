import { Smartphone as SmartphoneIcon } from "@mui/icons-material"
import { Avatar, Dialog, DialogTitle, Grid, IconButton, Typography } from "@mui/material"
import { useRouter } from "next/router"
import Qrcode from "qrcode.react"
import React from "react"
import useToggle from "react-use/lib/useToggle"
import { isMobile } from "src/utils"
interface Props {}

const Share: React.FC<Props> = (props) => {
  const router = useRouter()
  const [state, toggle] = useToggle(false)
  const [value, setValue] = React.useState("")
  React.useEffect(() => {
    setValue(document.location.href)
    router.events.on("routeChangeComplete", () => {
      setValue(document.location.href)
    })
  }, [])

  if (isMobile()) {
    return null
  }

  return (
    <Grid container spacing={3} sx={{ mt: "2rem" }}>
      <Grid item>
        <Typography>
          <IconButton onClick={toggle}>
            <Avatar>
              <SmartphoneIcon />
            </Avatar>
          </IconButton>
        </Typography>
        <Typography align="center" variant="caption" component="p">
          Share
        </Typography>
        <Dialog onClose={toggle} open={state}>
          <DialogTitle>Scan on your phone</DialogTitle>
          <Qrcode includeMargin level="H" size={250} value={value} />
        </Dialog>
      </Grid>
    </Grid>
  )
}
export default Share
