import React from "react"
import { SOLID_COLORS } from "../../ColorCheckbox"
import ButtonBase, { Props } from "../ButtonBase"
import ThreeTone from "../tones/ThreeTone"

const LayoutSearchMultiColorGRW: React.FC<Props> = (props) => {
  return (
    <ButtonBase {...props}>
      <ThreeTone colors={[SOLID_COLORS.green, SOLID_COLORS.red, SOLID_COLORS.white]} />
    </ButtonBase>
  )
}
export default LayoutSearchMultiColorGRW
