import React from "react"
import { SOLID_COLORS } from "../../ColorCheckbox"
import ButtonBase, { Props } from "../ButtonBase"
import TwoTone from "../tones/TwoTone"

const LayoutSearchMultiColorBR: React.FC<Props> = (props) => {
  return (
    <ButtonBase {...props}>
      <TwoTone colors={[SOLID_COLORS.black, SOLID_COLORS.red]} />
    </ButtonBase>
  )
}
export default LayoutSearchMultiColorBR
