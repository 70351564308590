import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { YouMayAlsoLikeItems } from "src/components/Product/Promotion"
interface Props {}

const LayoutSearchNoResults: React.FC<Props> = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box p={5}>
          <Typography variant="h2" align="center">
            No results
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <YouMayAlsoLikeItems />
      </Grid>
    </Grid>
  )
}
export default LayoutSearchNoResults
