import { TextFieldProps } from "@mui/material/TextField"
import React from "react"
import { EventFunction } from "react-hook-form"
import ReactHookFormProps from "./ReactHookFormProps"
import TextField from "./TextField"

interface Props {
  onChange?: EventFunction
}
const NumberTextField: React.FC<ReactHookFormProps & TextFieldProps & Props> = (
  props
) => {
  const thisInputProps = props.InputProps || { inputProps: {} }
  const { inputProps } = thisInputProps
  const integerOnly = inputProps && Number.isInteger(inputProps.min) && !inputProps.step
  const pattern = integerOnly ? { pattern: "[0-9]*" } : { step: "0.01" }
  const numberOnly = {
    inputProps: { ...thisInputProps.inputProps, type: "number", ...pattern },
  }

  const rules = props.rules || {}
  if (inputProps && inputProps.min) {
    rules.min = inputProps.min
  }

  if (inputProps && inputProps.max) {
    rules.max = inputProps.max
  }

  return (
    <TextField
      {...props}
      rules={rules}
      InputProps={{ ...thisInputProps, ...numberOnly }}
    />
  )
}
export default NumberTextField
