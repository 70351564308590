import { useRouter } from "next/router"
import React from "react"
import { useCheckout, useErrorMessage } from "src/machines"

const usePaymentStripeError = () => {
  const [message, set] = React.useState<string>(null)
  const router = useRouter()
  const checkout = useCheckout()
  const errorMessage = useErrorMessage()
  const { errors } = checkout.state.context

  React.useEffect(() => {
    if (!errors.stripe) return

    set(errors.stripe.message)
  }, [errors.stripe])

  React.useEffect(() => {
    if (!message) return

    errorMessage.send({
      type: "OPEN",
      message,
    })
  }, [message])

  React.useEffect(() => {
    if (errorMessage.state.matches("closing")) {
      router.reload()
    }
  }, [errorMessage.state.matches("closing")])
}
export default usePaymentStripeError
