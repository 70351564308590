import { useAtom } from "jotai"
import { atomWithMachine } from "jotai/xstate"
import { globalScope } from "."
import categoryDrawerMachine from "./categoryDrawer"

const categoryDrawerAtom = atomWithMachine((get) => categoryDrawerMachine)

const useCategoryDrawer = () => {
  const [state, send] = useAtom(categoryDrawerAtom, globalScope)

  return {
    state,
    send,
  }
}

export { useCategoryDrawer }
