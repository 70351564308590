import { css } from "@emotion/react"
import { Room as RoomIcon } from "@mui/icons-material"
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import Qrcode from "qrcode.react"
import React from "react"
import { useOrder } from "src/machines"
interface Props {}

const UserOrderPickup: React.FC<Props> = (props) => {
  const { state, send } = useOrder()
  const { order } = state.context

  return (
    <Card>
      <CardHeader
        title="Pickup Location"
        action={
          <IconButton
            href="https://goo.gl/maps/7Mnt8pjBoFSwGw8H6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Avatar className="pickup-location-icon">
              <RoomIcon />
            </Avatar>
          </IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <address>
              <strong>Mardi Gras Beads Factory</strong>
              <br />
              1145 US-90
              <br />
              Bridge City, LA 70094
              <br />
              <a href="tel:504-342-8888">504-342-8888</a>
            </address>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              align="center"
              css={css`
                canvas {
                  width: 100% !important;
                  height: 100% !important;
                  max-width: 100%;
                  max-height: 100%;
                }
              `}
            >
              <Qrcode includeMargin={false} level="H" size={400} value={order.url} />
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              * Please show this QR code when to pick up.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
export default UserOrderPickup
