import dayjs from "dayjs"
import { useRouter } from "next/router"
import React from "react"
import { usePageVisibility } from "react-page-visibility"
import { useProductLoader } from "src/machines"
const useLoader = () => {
  const router = useRouter()
  const isVisible = usePageVisibility()
  const { state, send } = useProductLoader()
  const invisibleDuration = React.useRef(new Date())

  React.useEffect(() => {
    router.events.on("routeChangeComplete", () => {
      send({ type: "REVALIDATE" })
    })
  }, [])

  React.useEffect(() => {
    if (isVisible && dayjs().diff(invisibleDuration.current, "minute") > 30) {
      send({ type: "REVALIDATE" })
    } else {
      invisibleDuration.current = new Date()
    }
  }, [isVisible])
}

export { useLoader }
