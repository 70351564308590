import { Close as CloseIcon } from "@mui/icons-material"
import { Dialog, DialogActions, IconButton, Tab, Tabs } from "@mui/material"
import { useSelector } from "@xstate/react"
import React from "react"
import { useInventory } from "../../atoms"
import ImageTab, { Props as ImageTabProps } from "./ImageTab"
import VideoTab from "./VideoTab"
interface Props extends ImageTabProps {
  onClose: () => void
  initialTabIndex: number
}

const ProductImageDefaultImageDialog: React.FC<Props> = (props) => {
  const service = useInventory()
  const vendProduct = useSelector(service, (state) => state.context.vendProduct)
  const { onClose, initialTabIndex } = props
  const [tabIndex, setTabIndex] = React.useState(initialTabIndex)

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
      <DialogActions>
        <Tabs
          variant="fullWidth"
          value={tabIndex}
          onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            setTabIndex(newValue)
          }}
        >
          <Tab label="VIDEOS" disabled={!vendProduct.hasYoutube} />
          <Tab label="IMAGES" />
        </Tabs>
        <div className="push-right" />

        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      {tabIndex == 0 && <VideoTab {...props} />}
      {tabIndex == 1 && <ImageTab {...props} />}
    </Dialog>
  )
}
export default ProductImageDefaultImageDialog
