import * as Sentry from "@sentry/browser"
import { useSession } from "next-auth/client"
import { useRouter } from "next/router"

const RESTRICTED_PATHS = ["/users"]

const useGateKeeper = () => {
  const [session, loading] = useSession()
  const loadingEnded = !loading
  // notLoggedIn = false and loggedIn = false can happen while [loading]
  const notLoggedIn = loadingEnded && !session
  const loggedIn = Boolean(loadingEnded && session)

  const router = useRouter()
  const requireAuth = RESTRICTED_PATHS.some((path) => router.pathname.startsWith(path))

  if (loggedIn) {
    Sentry.setUser({ email: session.user?.email, name: session.user?.name })
  } else {
    Sentry.configureScope((scope) => scope.setUser(null))
  }

  return {
    requireAuth,
    notLoggedIn,
    loggedIn,
  }
}

export { useGateKeeper }
