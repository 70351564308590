import { useAtom } from "jotai"
import { atomWithMachine } from "jotai/xstate"
import { globalScope } from "."
import loadingMachine from "./loading"

const loadingAtom = atomWithMachine((get) => loadingMachine)

const useLoading = () => {
  const [state, send] = useAtom(loadingAtom, globalScope)

  return {
    state,
    send,
  }
}

export { useLoading }
